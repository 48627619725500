/* eslint-disable camelcase,no-param-reassign,no-case-declarations */
import { createRoutine } from 'redux-saga-routines';
import { filter, isEmpty, map, uniqBy } from 'lodash';
import { IChildRedux } from './children.interface';
import { CheckoutMode, DEFAULT_ORG_FILTERS } from '../../constants';

export const ON_SCROLLED = createRoutine('ON_SCROLLED');

export const ON_GET_CHILDREN = createRoutine('ON_GET_CHILDREN');
export const ON_ADD_ITEM_TO_BASKET = createRoutine('ON_ADD_ITEM_TO_BASKET');
export const ON_REMOVE_ITEM_FROM_BASKET = createRoutine(
  'ON_REMOVE_ITEM_FROM_BASKET'
);
export const ON_CLEAR_BASKET = createRoutine('ON_CLEAR_BASKET');

export const ON_SHOW_EMPTY_SEARCH_MODAL = createRoutine(
  'ON_SHOW_EMPTY_SEARCH_MODAL'
);

export const ON_SET_FILTERS = createRoutine('ON_SET_FILTERS');

export const ON_SET_CHECKOUT_MODE = createRoutine('ON_SET_CHECKOUT_MODE');

export const INITIAL_STATE: IChildRedux = {
  basket: [],
  children: [],
  basketCount: 0,
  getChildrenLoading: true,
  defaultSearchLoading: false,
  hasScrolled: false,
  childrenSuccess: false,
  isSearch: false,
  statesLoading: false,
  showMore: false,
  isResultEmpty: false,
  showNationalThresholdMessage: false,
  allSponsored: true,
  filters: DEFAULT_ORG_FILTERS,
  numDonatedGifts: 0,
  numUndonatedGifts: 0,
  numRequestedGifts: 0,
  errMessage: '',
  checkoutMode: CheckoutMode.SPECIFIC_CHILDREN,
};

const ChildReducer = (
  state = INITIAL_STATE,
  { type, payload = {} }: { type: string; payload: any }
) => {
  switch (type) {
    case ON_GET_CHILDREN.TRIGGER:
      if (payload.isEmptySearch || payload.start === 0) {
        return {
          ...state,
          children: [],
          paginationStart: 0,
          defaultSearchLoading: !!payload.isEmptySearch,
          showNationalThresholdMessage: false,
          errMessage: '',
          getChildrenLoading: !payload.isEmptySearch,
        };
      }
      if (payload.caregiver_id) {
        return {
          ...state,
          getChildrenLoading: true,
        };
      }
      return {
        ...state,
        getChildrenLoading: true,
        childrenSuccess: false,
        showNationalThresholdMessage: false,
        errMessage: '',
      };
    case ON_GET_CHILDREN.SUCCESS:
      const allGifts = [...state.children, ...payload.children];
      return {
        ...state,
        children: allGifts,
        isSearch: payload.isSearch,
        showMore: payload.showMore,
        isResultEmpty: payload.isEmptySearch,
        getChildrenLoading: false,
        childrenSuccess: true,
        allSponsored: isEmpty(allGifts) && !payload.isSearch,
        errMessage: payload.errMessage,
        numRequestedGifts: payload.numRequestedGifts,
        numDonatedGifts: payload.numDonatedGifts,
        numUndonatedGifts: payload.numUndonatedGifts,
      };
    case ON_GET_CHILDREN.FAILURE:
      return {
        ...state,
        showNationalThresholdMessage: payload.showNationalThresholdMessage,
        errMessage: payload.errMessage,
      };
    case ON_GET_CHILDREN.FULFILL:
      if (payload.isEmptySearch) {
        return {
          ...state,
          defaultSearchLoading: false,
          isSearch: payload.isSearch,
        };
      }
      return { ...state, getChildrenLoading: false };

    case ON_ADD_ITEM_TO_BASKET.TRIGGER: {
      const stateBasket = state.basket;
      let tempBasket: any[] = [];
      let count = 0;
      if (Array.isArray(payload.data)) {
        tempBasket = [...stateBasket, ...payload.data];
        tempBasket = tempBasket.map((data = {}) => {
          if (!data.checked) {
            data.checked = true;
          }
          return data;
        });
        tempBasket = uniqBy(tempBasket, 'id');
        count = tempBasket.length;
      } else {
        tempBasket = [payload.data, ...stateBasket];
        tempBasket = uniqBy(tempBasket, 'id');
        count = tempBasket.length;
      }
      return {
        ...state,
        basket: tempBasket,
        basketCount: count,
      };
    }

    case ON_REMOVE_ITEM_FROM_BASKET.TRIGGER: {
      const stateBasket = state.basket;
      const tempBasket = filter(stateBasket, (item) => {
        return item.id !== payload.data?.id && item.id !== 0;
      });
      if (payload.isReviewBasket) {
        const tempChildren = map(state.children, (item) => {
          if (item.id === payload.data?.id) {
            item.checked = false;
          }
          return item;
        });
        return {
          ...state,
          basket: tempBasket,
          basketCount: tempBasket.length,
          children: tempChildren,
        };
      }
      return {
        ...state,
        basket: tempBasket,
        basketCount: tempBasket.length,
      };
    }
    case ON_SCROLLED.TRIGGER:
      return {
        ...state,
        hasScrolled: true,
      };
    case ON_CLEAR_BASKET.TRIGGER:
      return {
        ...state,
        basket: INITIAL_STATE.basket,
        children: INITIAL_STATE.children,
        filters: DEFAULT_ORG_FILTERS,
        basketCount: INITIAL_STATE.basketCount,
        hasScrolled: INITIAL_STATE.hasScrolled,
      };

    case ON_SHOW_EMPTY_SEARCH_MODAL.TRIGGER:
      return { ...state, isResultEmpty: payload };

    case ON_SET_FILTERS.TRIGGER:
      if (!payload.state) {
        return { ...state, counties: [], filters: payload };
      }
      return { ...state, filters: payload };

    case ON_SET_CHECKOUT_MODE.TRIGGER:
      return { ...state, checkoutMode: payload };

    default:
      return state;
  }
};

export default ChildReducer;
