import { call } from 'redux-saga/effects';

export class CustomError extends Error {
  statusCode;

  data;

  constructor(statusCode = 503, data: any, ...params: any) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }
    this.name = 'CustomError';
    this.statusCode = statusCode;
    this.data = data;
  }
}

const getMessage = (error: string) => {
  if (error === 'TIMEOUT_ERROR') {
    return 'No Response From Server.';
  }
  if (error === 'CONNECTION_ERROR') {
    return 'Server Is Not Available.';
  }
  if (error === 'NETWORK_ERROR') {
    return 'Network not available.';
  }
  return 'Something went wrong. Please try again';
};

export const extractMessage = (resData: any, problem: string, status, url) => {
  if (status === 502) {
    return `API call to ${url} timed out. Please try again.`;
  }
  if (resData) {
    if (typeof resData.error === 'object' && resData.error.message) {
      return resData.error.message;
    }
    if (resData.message) {
      return resData.message;
    }
    if (resData.error) {
      return resData.error;
    }
    if (resData) {
      return resData;
    }
    return getMessage(resData);
  }
  return getMessage(problem);
};

function* callServer(
  apiFunction: (...args: any[]) => any,
  reqData = { id: undefined }
): any {
  const response = yield call(apiFunction, reqData, reqData.id);
  const {
    data: resData = {},
    ok = false,
    problem = 'TIMEOUT_ERROR',
    status = 500,
  } = response || {};

  if (ok) {
    return { res: resData, statusCode: status };
  }
  const message = extractMessage(
    resData || {},
    problem,
    status,
    response.config.url
  );
  throw new CustomError(status, resData, message);
}

export default {
  callServer,
};
