import React, { PropsWithChildren } from 'react';

export interface IRBPCardProps extends PropsWithChildren {}

const RBPCard: React.FC<IRBPCardProps> = ({ children }) => {
  return (
    <div className="m-0 rbp-card">
      <div className="rbp-card-content">{children}</div>
    </div>
  );
};

export default RBPCard;
