import React from 'react';
import parse from 'html-react-parser';

export const ParseHtmlMessage = (message: string) => {
  return parse(message);
};

export interface IHtmlTextMessageProps {
  html: string;
}

export const HtmlTextMessage: React.FC<IHtmlTextMessageProps> = ({ html }) => {
  return (
    <div className="text-message-style">
      <div className="text-style">{parse(html)}</div>
    </div>
  );
};
