import { createRoutine } from 'redux-saga-routines';
import { IState } from '../types';

export const GET_STATES_DATA = createRoutine('GET_STATES_DATA');

export interface IStatesRedux {
  states: Array<IState>;
}

export type IStatesData = IStatesDataApi & IStatesDataUi;

interface IStatesDataApi {
  states: Array<IState>;
}

interface IStatesDataUi {
  isLoading: boolean;
  hasBeenRequested: boolean;
  states: Array<IState>;
}

export const INITIAL_STATE: IStatesData = {
  states: new Array<IState>(),
  isLoading: false,
  hasBeenRequested: false,
};

export const stateDataReducer = (
  state = INITIAL_STATE,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case GET_STATES_DATA.TRIGGER:
      return { ...state };
    case GET_STATES_DATA.SUCCESS:
      return { ...state, ...payload };
    case GET_STATES_DATA.FAILURE:
      return state;
    case GET_STATES_DATA.FULFILL:
      return { ...state };

    default:
      return state;
  }
};
