import React from 'react';
import { AngelTreeLogo } from '../../constants/images';
import './offline.scss';

export const Offline: React.FC<any> = () => {
  return (
    <div className="offline-component">
      <AngelTreeLogo className="logo" />
      <h2 className="header-second">Angel Tree is Offline for Maintenance</h2>
      <p className="sub-text">
        Please check back later, and have a blessed day!
      </p>
    </div>
  );
};
