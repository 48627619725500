import { ApisauceInstance } from 'apisauce';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import Api from '../services/api-caller';
import { GET_STATES_DATA } from '../redux/states.redux';
import { IRefData } from '../redux/refdata.redux';
import { refDataState } from '../selectors/refdata.selector';

export function* onGetStateRequest(api, headers, { payload }) {
  try {
    const refData: IRefData = yield select(refDataState);
    if (
      refData.appSettings.areVatAccessCodesEnabled &&
      headers.publicid &&
      headers.accesscode
    ) {
      const { res = {} } = yield call(Api.callServer, api, payload);
      yield put(GET_STATES_DATA.success(res));
    } else if (
      !refData.appSettings.areVatAccessCodesEnabled &&
      headers.publicid
    ) {
      const { res = {} } = yield call(Api.callServer, api, payload);
      yield put(GET_STATES_DATA.success(res));
    }
  } catch ({ message }) {
    yield put(GET_STATES_DATA.failure(message));
    //toastError(`{message}`);
  } finally {
    yield put(GET_STATES_DATA.fulfill());
  }
}

export default (api: ApisauceInstance) => {
  const { headers } = api;
  const onGetStatesRequestApi = (data: any) => api.get('/States', data);

  return [
    // @ts-ignore
    takeEvery(
      // @ts-ignore
      GET_STATES_DATA.TRIGGER,
      onGetStateRequest,
      onGetStatesRequestApi,
      headers
    ),
  ];
};
