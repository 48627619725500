// eslint-disable-next-line import/prefer-default-export
export const FOOTER_LINKS = {
  ABOUT: {
    URL:
      'https://www.prisonfellowship.org/about/angel-tree?utm_source=PF-Website&utm_medium=VAT-App&utm_campaign=MKG-AT-CH-VAT-Application-FY21',
    NAME: 'Prison Fellowship Home',
  },
  DONATE: {
    URL:
      'https://secure.prisonfellowship.org/donate/AngelTree?ms=PATWL0G200102001&utm_source=PF-Website&utm_medium=VAT-App&utm_campaign=MKG-AT-CH-VAT-Application-FY21',
    NAME: 'Donate',
  },
  SUBSCRIBE: {
    URL:
      'https://www.prisonfellowship.org/resources/subscribe/?utm_source=PF-Website&utm_medium=VAT-App&utm_campaign=MKG-AT-CH-VAT-Application-FY21',
    NAME: 'Subscribe',
  },
  SHARE: {
    URL: 'https://www.prisonfellowship.org/action/',
    NAME: 'Volunteer',
  },
};

export const EXTERNAL_LINKS = {
  ALTERNATIVE_DONATION: {
    URL:
      'https://secure.prisonfellowship.org/donate/ATChristmas?ms=AVWL00230517001',
  },
};
