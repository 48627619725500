import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { EGender, EGenderMap } from '../../redux/refdata.redux';
import { PfCheckboxChecked, PfCheckboxUnchecked } from '../../constants/images';

interface Props {
  isBoyChecked: boolean;
  isGirlChecked: boolean;
  handleBoyClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  handleGirlClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  genders: EGender[];
}

export const GenderFilter = (props: Props) => {
  const {
    isBoyChecked,
    isGirlChecked,
    handleBoyClick,
    handleGirlClick,
    genders,
  } = props;
  return (
    <FormGroup className="gender-filter">
      {genders.map((gender) => (
        <FormControlLabel
          key={gender.toString()}
          control={
            <Checkbox
              icon={<PfCheckboxUnchecked />}
              checkedIcon={<PfCheckboxChecked />}
              checked={
                gender.toString() === EGender[EGender.M]
                  ? isBoyChecked
                  : isGirlChecked
              }
              onChange={(e, t) =>
                gender.toString() === EGender[EGender.M]
                  ? handleBoyClick(e, t)
                  : handleGirlClick(e, t)
              }
            />
          }
          label={EGenderMap[gender] || ''}
        />
      ))}
    </FormGroup>
  );
};

export default GenderFilter;
