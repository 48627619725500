import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, ModalBody } from 'reactstrap';
import { Loading as LoadingProgress } from '../../components/common';
import { getAuthReduxState } from './auth.selector';
import { ON_GET_CHILDREN, getChildrenReduxState } from '../children';
import AuthForm from './authForm';

const Auth = () => {
  const dispatch = useDispatch();

  const { orgInfo, loading = false } = useSelector(getAuthReduxState);
  const { children = [], childrenSuccess = false } = useSelector(
    getChildrenReduxState
  );

  const [isOpenCodeModal, setIsOpenCodeModal] = useState(false);

  useEffect(() => {
    if (!isEmpty(orgInfo) && !orgInfo.isNationalOrg && !loading) {
      setIsOpenCodeModal(true);
    }
  }, [orgInfo, loading]);

  useEffect(() => {
    if (!isEmpty(children) && childrenSuccess) {
      setIsOpenCodeModal(false);
    }
  }, [children, childrenSuccess]);

  const onSubmitCode = (value: { code: string }) => {
    dispatch(
      ON_GET_CHILDREN({
        code: value.code,
        isNavigate: true,
      })
    );

  };

  const _renderHeading = () => {
    return (
      <div className="auth-heading">
        <span className="heading-main">Welcome to</span>
        <br />
        <span className="heading-main">Virtual Angel Tree</span>
      </div>
    );
  };

  const _renderOrganizationLabel = () => {
    return (
      <div className="organization-label">
        <span>{`For ${orgInfo.orgName || ''}`}</span>
      </div>
    );
  };

  const _renderModalBody = () => {
    return (
      <div className="auth-body">
        <div className="body-desc">
          Please enter the access code from your group&apos;s Coordinator
        </div>
        <div className="auth-action">
          <AuthForm onSubmit={onSubmitCode} />
        </div>
      </div>
    );
  };

  const _renderModal = () => {
    return (
      <>
        {_renderHeading()}
        {_renderOrganizationLabel()}
        {_renderModalBody()}
      </>
    );
  };

  return (
    <>
      {loading && <LoadingProgress fullPage />}
      <Modal isOpen={isOpenCodeModal} className="auth-modal" backdrop>
        <ModalBody>{_renderModal()}</ModalBody>
      </Modal>
    </>
  );
};

export default Auth;
