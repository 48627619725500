import React from 'react';
import PfButton from './pf-button';

export interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  checked?: boolean;
}

const PfCardButton: React.FC<IButtonProps> = (props) => {
  const { onClick, checked } = props;

  const text = checked ? 'DESELECT' : 'SPONSOR ME';
  const buttonStyleVariant = checked ? 'transparent-primary' : 'solid-primary';

  return (
    <PfButton
      onClick={onClick as VoidFunction}
      styleVariant={buttonStyleVariant}
    >
      {text}
    </PfButton>
  );
};

export default PfCardButton;
