export const DEFAULT_ORG_FILTERS = {
  isGirl: false,
  isBoy: false,
  state: '',
  county_id: '',
  ageRange: [0, 18],
};

export const DEFAULT_NAT_ORG_FILTERS = {
  ...DEFAULT_ORG_FILTERS,
  isHighestNeed: true,
};
