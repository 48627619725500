import { createRoutine } from 'redux-saga-routines';
import { ICounty } from '../types';

export const ON_GET_STATE_COUNTY = createRoutine('ON_GET_STATE_COUNTY');

export interface ICountiesRedux {
  counties: Array<ICounty>;
}

export type ICountiesData = ICountyDataApi & ICountyDataUi;

interface ICountyDataApi {
  counties: Array<ICounty>;
}

interface ICountyDataUi {
  isLoading: boolean;
  hasBeenRequested: boolean;
  counties: Array<ICounty>;
}

export const INITIAL_STATE: ICountiesData = {
  counties: new Array<ICounty>(),
  isLoading: false,
  hasBeenRequested: false,
};

export const countiesDataReducer = (
  state = INITIAL_STATE,
  { type, payload = {} }: { type: string; payload: any }
) => {
  switch (type) {
    case ON_GET_STATE_COUNTY.TRIGGER:
      return { ...state };
    case ON_GET_STATE_COUNTY.SUCCESS:
      return { ...state, ...payload };
    case ON_GET_STATE_COUNTY.FAILURE:
      return { ...state };
    case ON_GET_STATE_COUNTY.FULFILL:
      return { ...state };
    default:
      return state;
  }
};
