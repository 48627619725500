/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { IReduxState } from '../../redux/redux.interface';

const getPaymentState = (state: IReduxState) => state.payment;

export const getPaymentReduxState = createSelector(
  [getPaymentState],
  (state) => state
);
