// Set Theme here 3/3 (Assets)
// Unfortunately cannot abstract the "theme" selection path to a variable. "require" is a build time function, so variables are not available yet.
import { Themes } from './theme';
import { THEME } from '../config';

import { ReactComponent as pfLogo } from '../assets/images/pf-at-logo-wide.svg';
import { ReactComponent as iconCart } from '../assets/images/icon-cart.svg';
import { ReactComponent as ornamentBlue } from '../assets/images/ornament-blue.svg';
import { ReactComponent as ornamentRed } from '../assets/images/ornament-red.svg';
import { ReactComponent as ornamentRedShort } from '../assets/images/ornament-red-short.svg';
import { ReactComponent as atGirl } from '../assets/images/at-girl.svg';
import { ReactComponent as atGirl2 } from '../assets/images/at-girl2.svg';
import { ReactComponent as atBoy } from '../assets/images/at-boy.svg';
import pfBoy from '../assets/images/pfchristmas/Boy.png';
import pfGirl from '../assets/images/pfchristmas/Girl.png';
import pfImageBorder from '../assets/images/pfchristmas/img-border.png';
import pfLeftLayer from '../assets/images/pfchristmas/left-layer.png';
import pfRightLayer from '../assets/images/pfchristmas/right-layer.png';
import pfcheckmark from '../assets/images/pfchristmas/checkmark.png';
import pfCandyStrip from '../assets/images/pfchristmas/candy-cane-strip.png';

import { ReactComponent as pfCheckboxChecked } from '../assets/images/pf-checkbox-checked.svg';
import { ReactComponent as pfCheckboxUnchecked } from '../assets/images/pf-checkbox-unchecked.svg';

import { ReactComponent as iconCheckCircle } from '../assets/images/icon-check-circle.svg';
import { ReactComponent as iconUncheckCircle } from '../assets/images/icon-uncheck-circle.svg';

import { ReactComponent as pfLogo2 } from '../assets/images/pfbrand/logo-vertical.svg';
import pfBoy2 from '../assets/images/pfbrand/Boy.png';
import pfGirl2 from '../assets/images/pfbrand/Girl.png';
import pfImageBorder2 from '../assets/images/pfbrand/img-border.png';
import pfLeftLayer2 from '../assets/images/pfbrand/left-layer.png';
import pfRightLayer2 from '../assets/images/pfbrand/right-layer.png';
import pfcheckmark2 from '../assets/images/pfbrand/checkmark.png';
import pfCandyStrip2 from '../assets/images/pfbrand/candy-cane-strip.png';

import { ReactComponent as gift1 } from '../assets/images/gifts/gift1.svg';
import { ReactComponent as gift2 } from '../assets/images/gifts/gift2.svg';
import { ReactComponent as gift3 } from '../assets/images/gifts/gift3.svg';

export const Gift1 = gift1;
export const Gift2 = gift2;
export const Gift3 = gift3;

export const AngelTreeLogo = Themes.PF_CHRISTMAS === THEME ? pfLogo : pfLogo2;
export const Boy = Themes.PF_CHRISTMAS === THEME ? pfBoy : pfBoy2;
export const Girl = Themes.PF_CHRISTMAS === THEME ? pfGirl : pfGirl2;

export const IconCart = iconCart;
export const OrnamentBlue = ornamentBlue;
export const OrnamentRed = ornamentRed;
export const OrnamentRedShort = ornamentRedShort;
export const PfCheckboxChecked = pfCheckboxChecked;
export const PfCheckboxUnchecked = pfCheckboxUnchecked;
export const IconCheckedCircle = iconCheckCircle;
export const IconUncheckedCircle = iconUncheckCircle;

export const checkmark =
  Themes.PF_CHRISTMAS === THEME ? pfcheckmark : pfcheckmark2;
export const imageBorder =
  Themes.PF_CHRISTMAS === THEME ? pfImageBorder : pfImageBorder2;
export const leftLayer =
  Themes.PF_CHRISTMAS === THEME ? pfLeftLayer : pfLeftLayer2;
export const rightLayer =
  Themes.PF_CHRISTMAS === THEME ? pfRightLayer : pfRightLayer2;
export const CandyStrip =
  Themes.PF_CHRISTMAS === THEME ? pfCandyStrip : pfCandyStrip2;

export const SUB_HEADER_IMAGES = [atBoy, atGirl, atGirl2];
