import { put, takeEvery, call, select } from 'redux-saga/effects';
import { isEmpty, isEqual } from 'lodash';
import { replace } from 'connected-react-router';
import { ApisauceInstance } from 'apisauce';

import { ON_GET_ORGANIZATION_INFO } from './auth.redux';
import Api from '../../services/api-caller';
import { ALERT_MESSAGE, ROUTES, TEXT_CONSTANTS } from '../../constants';
import { toastError, toastSuccess } from '../../utils';
import { refDataState } from './../../selectors/refdata.selector';
import { IRefData } from '../../redux/refdata.redux';
import { ON_GET_CHILDREN } from '../children';

export function* onGetOrganizationRequest(
  api: any,
  setHeader: any,
  deleteHeader: any,
  { payload }: any
) {
  try {
    const { error: isError = false, ...rest } = payload || {};
    const refData: IRefData = yield select(refDataState);

    const { res = {} } = yield call(Api.callServer, api, rest);

    if (!isEmpty(res.organization)) {
      const { organization = {} } = res || {};
      setHeader('publicid', organization.publicId);
      yield put(ON_GET_ORGANIZATION_INFO.success({ orgInfo: organization }));
      if (organization.isNationalOrg) {
        yield put(replace(`/${organization.publicId}/${ROUTES.CHILDREN.path}`));
      } else if (!refData.appSettings.areVatAccessCodesEnabled) {
        yield put(ON_GET_CHILDREN({ isNavigate: true }));
      } else {
        yield put(replace(`/${organization.publicId}`));
      }

      if (isError) {
        toastSuccess(ALERT_MESSAGE.DEFAULT_NAT_ORG);
      }
    }
  } catch ({ message, statusCode }) {
    yield put(replace('/'));
    yield put(ON_GET_ORGANIZATION_INFO.failure(message));
    if (
      statusCode === 400 &&
      isEqual(message, TEXT_CONSTANTS.ORG_ERROR_MSG_BE)
    ) {
      yield put(ON_GET_ORGANIZATION_INFO.fulfill());
      deleteHeader('publicid');
      yield put(ON_GET_ORGANIZATION_INFO.trigger({ error: true }));
    } else {
      toastError(`${message}`);
    }
  } finally {
    yield put(ON_GET_ORGANIZATION_INFO.fulfill());
  }
}

export default (api: ApisauceInstance) => {
  const { setHeader, deleteHeader } = api;
  const onGetOrganizationInfoRequestApi = (data: any) =>
    api.get('/Organizations', data);

  return [
    takeEvery(
      // @ts-ignore
      ON_GET_ORGANIZATION_INFO.TRIGGER,
      onGetOrganizationRequest,
      onGetOrganizationInfoRequestApi,
      setHeader,
      deleteHeader
    ),
  ];
};
