/* eslint-disable no-param-reassign,camelcase,no-lonely-if */
import { call, put, takeEvery } from 'redux-saga/effects';
import { ApisauceInstance } from 'apisauce';

import { GET_REF_DATA } from '../redux/refdata.redux';
import Api from '../services/api-caller';

// @ts-ignore
export function* getRefDataRequest(api, { payload }) {
  try {
    const { res = {} } = yield call(Api.callServer, api, payload);
    yield put(GET_REF_DATA.success(res.refData));
  } catch ({ message }) {
    yield put(GET_REF_DATA.failure(message));
  } finally {
    yield put(GET_REF_DATA.fulfill());
  }
}

export default (api: ApisauceInstance) => {
  const getRefDataRequestApi = (data: any) => api.get('/RefData', data);

  return [
    // @ts-ignore
    takeEvery(GET_REF_DATA.TRIGGER, getRefDataRequest, getRefDataRequestApi),
  ];
};
