import React from 'react';

export interface ILoadingProps {
  fullPage?: boolean;
  className?: string;
}

const Loading: React.FC<ILoadingProps> = (props) => {
  const { fullPage = false, className = '' } = props;
  return fullPage ? (
    <div className="page-loading">
      {' '}
      <i className="fa fa-circle-o-notch fa-spin fa-3x" />
    </div>
  ) : (
    <span className={className}>
      {' '}
      <i className="fa fa-circle-o-notch fa-spin" />
    </span>
  );
};

export default Loading;
