export interface IRouteData {
  key: string;
  path: string;
  exact?: boolean;
}

export interface IRoute {
  [key: string]: IRouteData;
}

const ROUTES: IRoute = {
  AUTH: { key: 'auth', path: '/' },
  CHILDREN: {
    key: 'children',
    path: 'children',
    exact: true,
  },
  REVIEW_BASKET: {
    key: 'review_basket',
    path: 'review-basket',
    exact: true,
  },
  PAYMENT: {
    key: 'payment',
    path: 'payment-info',
    exact: true,
  },
  EXIT: { key: 'exit', path: 'exit', exact: true },
};
export default ROUTES;
