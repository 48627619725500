import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import { createMigrate, persistReducer } from 'redux-persist';
import { reducer as formReducer } from 'redux-form';

import {
  refDataReducer,
  INITIAL_STATE as REFDATA_INITIAL_STATE,
} from './refdata.redux';
import {
  childrenRedux,
  INITIAL_STATE as CHILDREN_INITIAL_STATE,
} from '../pages/children';
import { authRedux, INITIAL_STATE as AUTH_INITIAL_STATE } from '../pages/auth';
import {
  paymentRedux,
  INITIAL_STATE as PAYMENT_INITIAL_STATE,
} from '../pages/payment';
import {
  stateDataReducer,
  INITIAL_STATE as STATES_INITIAL_STATE,
} from './states.redux';
import {
  countiesDataReducer,
  INITIAL_STATE as COUNTIES_INITIAL_STATE,
} from './counties.redux';
export const APP_INITIAL_STATE = {
  refData: REFDATA_INITIAL_STATE,
  children: CHILDREN_INITIAL_STATE,
  auth: AUTH_INITIAL_STATE,
  payment: PAYMENT_INITIAL_STATE,
  states: STATES_INITIAL_STATE,
  counties: COUNTIES_INITIAL_STATE,
};

const migrations: any = {
  1: (_state) => ({ ...APP_INITIAL_STATE }),
};

const PERSIST_CONFIG_CHILDREN_REDUCER = {
  key: 'children',
  storage,
  version: 1,
  migrate: createMigrate(migrations),
  whitelist: [],
};

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    refData: refDataReducer,
    statesData: stateDataReducer,
    countiesData: countiesDataReducer,
    children: persistReducer(PERSIST_CONFIG_CHILDREN_REDUCER, childrenRedux),
    auth: authRedux,
    payment: paymentRedux,
  });

export default rootReducer;
