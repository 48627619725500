import { EXTERNAL_LINKS } from '../constants';
import { Env } from './env';

export const onUnload = (e: any) => {
  e.preventDefault();
  e.returnValue = '';
};

export const addUnloadEvent = () =>
  window.addEventListener('beforeunload', onUnload);

export const removeUnloadEvent = () =>
  window.removeEventListener('beforeunload', onUnload);

export const redirectToStaticDonationSite = () => {
  if (Env.IS_STATIC_DONATION_SITE_REDIRECT_ENABLED) {
    window.location.replace(EXTERNAL_LINKS.ALTERNATIVE_DONATION.URL);
  }
};
