import React, { useEffect, PropsWithChildren } from 'react';
import { RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ON_SCROLLED } from '../../pages/children';

export interface IScrollToTopProps
  extends RouteComponentProps,
    PropsWithChildren {}

// TODO: Unused? Non-functional? Rip out?
const ScrollToTop: React.FC<IScrollToTopProps> = ({ history, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const scrollHandler = () => {
      dispatch(ON_SCROLLED());
      document.removeEventListener('scroll', scrollHandler);
    };
    document.addEventListener('scroll', scrollHandler);

    const unlisten = history.listen(() => {
      const rootElement = document.getElementById('root');
      if (rootElement) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    });

    return () => {
      document.removeEventListener('scroll', scrollHandler);
      unlisten();
    };
  }, [history]);

  return <>{children}</>;
};

export default withRouter(ScrollToTop);
