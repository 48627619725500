import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { forEach, isEmpty } from 'lodash';

import { Loading, RBPCard } from '../../components/common';
import { CheckoutMode, ROUTES, TEXT_CONSTANTS } from '../../constants';
import PaymentForm from './paymentForm';
import { ON_SPONSOR_GIFT } from './payment.redux';
import { toastError, useCalSum, useNavIfBasketEmpty } from '../../utils';
import { getChildrenReduxState } from '../children';
import { getAuthReduxState } from '../auth';
import { getPaymentReduxState } from './payment.selector';
import { IObj } from '../../types';
import { GET_STATES_DATA } from '../../redux/states.redux';

import { DonationEntityTypeId } from '../../redux/refdata.redux';
import { getStatesState } from '../children/children';

const Payment = () => {
  const dispatch = useDispatch();

  const {
    getChildrenLoading = false,
    basket = [],
    statesLoading = false,
    checkoutMode,
  } = useSelector(getChildrenReduxState);
  const { states = [] } = useSelector(getStatesState);
  const { orgInfo } = useSelector(getAuthReduxState);
  const {
    sponsorLoading = false,
    accessCode = '',
    isPaymentError = false,
  } = useSelector(getPaymentReduxState);

  const [showCCInputs, setShowCCInputs] = useState(true);
  const { isNationalOrg } = orgInfo;

  const isBulkDonation = checkoutMode === CheckoutMode.BULK_DONATION;

  useEffect(() => {
    if (isEmpty(states) && !isEmpty(orgInfo)) {
      if (!isEmpty(accessCode) || isNationalOrg) {
        dispatch(GET_STATES_DATA());
      }
    }
    if (isPaymentError) {
      toastError(TEXT_CONSTANTS.PAYMENT_ERROR_MSG);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useNavIfBasketEmpty(basket);
  const selectiveDonationTotal = useCalSum(orgInfo, basket);
  const onSelectCCTab = (value: boolean) => {
    setShowCCInputs(value);
  };

  const onHandleBack = () => {
    dispatch(push(ROUTES.REVIEW_BASKET.path));
  };

  const onHandleNext = (values: IObj) => {
    const {
      state = {},
      account_type = {},
      card_number,
      card_cvv,
      card_expiration_year,
      card_expiration_month,
      routing_number,
      account_number,
      bank_name,
      account_name,
      donation_entity_type_id = DonationEntityTypeId.UNSPECIFIED.toString(),
      ...rest
    } = values || {};

    let payload: IObj = {
      state: state?.shortCode,
      donation_entity_type_id: parseInt(donation_entity_type_id),
      ...rest,
    };

    if (isBulkDonation && basket?.[0]?.bulkDonationTotal) {
      payload = {
        ...payload,
        bulk_donation_total: parseInt(basket?.[0]?.bulkDonationTotal),
      };
    } else {
      const gift_ids: Array<number> = [];

      forEach(basket, (item) => {
        gift_ids.push(item.id);
      });

      payload = {
        ...payload,
        gift_ids,
        bulk_donation_total: undefined,
      };
    }

    if (showCCInputs) {
      payload = {
        ...payload,
        payment_type: 'CC',
        card_number,
        card_cvv,
        card_expiration_year,
        card_expiration_month: parseInt(card_expiration_month, 10).toString(),
      };
    } else {
      payload = {
        ...payload,
        payment_type: 'ACH',
        routing_number,
        account_number,
        bank_name,
        account_name,
        account_type: account_type.value,
      };
    }
    dispatch(ON_SPONSOR_GIFT(payload));
  };

  const _renderRBPCardContent = () => {
    return (
      <>
        <div className="my-2 tab-container">
          <div
            className={`ach-style my-2 ${showCCInputs ? '' : 'selected-tab'}`}
            onClick={showCCInputs ? () => onSelectCCTab(false) : undefined}
          >
            PAY WITH ACH
          </div>
          <div
            className={`credit-card-style my-2 ${
              showCCInputs ? 'selected-tab' : ''
            }`}
            onClick={showCCInputs ? undefined : () => onSelectCCTab(true)}
          >
            PAY WITH CREDIT CARD
          </div>
        </div>
        <PaymentForm
          onSubmit={onHandleNext}
          total={
            isBulkDonation
              ? parseInt(basket?.[0]?.bulkDonationTotal || '0')
              : selectiveDonationTotal
          }
          showCCInputs={showCCInputs}
          onHandleBack={onHandleBack}
          isPaymentError={isPaymentError}
          initialValues={{
            donation_entity_type_id: DonationEntityTypeId.PERSON.toString(),
          }}
        />
      </>
    );
  };

  return (
    <>
      {(statesLoading || getChildrenLoading || sponsorLoading) && (
        <Loading fullPage />
      )}
      <div className="payment-container">
        <div className="heading">PAYMENT INFORMATION</div>
        <div className="payment-content">
          <RBPCard>{_renderRBPCardContent()}</RBPCard>
        </div>
      </div>
    </>
  );
};

export default Payment;
