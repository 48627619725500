import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { MutableRefObject } from 'react';

export const toastInfo = (message: string) => {
  if (isEmpty(message)) {
    return null;
  }
  return toast.info(message, { position: 'top-left' });
};

export const toastSuccess = (message: string) => {
  if (isEmpty(message)) {
    return null;
  }
  return toast.success(message, { position: 'top-left' });
};

export const toastError = (message: string) => {
  if (isEmpty(message)) {
    return null;
  }
  return toast.error(message, { position: 'top-left' });
};

export const removeToast = (toastRef: MutableRefObject<any>) => {
  return toast.dismiss(toastRef?.current);
};

export const updateToast = (toastRef: MutableRefObject<any>) => {
  return toast.update(toastRef?.current, {
    type: toast.TYPE.INFO,
    autoClose: 5000,
  });
};

export const isToastActive = (toastRef: MutableRefObject<any>) =>
  toast.isActive(toastRef?.current);
