import React from 'react';
import { Route } from 'react-router-dom';
import MainLayout from './layout';
import { Auth, Children, Exit, Payment, ReviewBasket } from './pages';
import { IRouteData } from './constants/routes';

export interface IRouteCompObj {
  [key: string]: React.FC<any>;
}

export const ROUTE_COMPONENTS: IRouteCompObj = {
  AUTH: Auth,
  CHILDREN: Children,
  REVIEW_BASKET: ReviewBasket,
  PAYMENT: Payment,
  EXIT: Exit,
};

export interface IRouteWrapperProps extends IRouteData {
  component: React.FC<any>;
}

export const RouteWrapper = ({
  component: Component,
  ...rest
}: IRouteWrapperProps) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <MainLayout {...props} {...rest}>
            <Component {...props} />
          </MainLayout>
        );
      }}
    />
  );
};
