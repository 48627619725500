import { createRoutine } from 'redux-saga-routines';

export const GET_REF_DATA = createRoutine('GET_REF_DATA');

export type IRefData = IRefDataApi & IRefDataUi;

interface IRefDataApi {
  appSettings: IAppSettings;
}

interface IRefDataUi {
  isLoading: boolean;
  hasBeenRequested: boolean;
  genders: [];
}

interface IAppSettings {
  programYear: number;
  areVolAppNextYearRegistrationsEnabled: boolean;
  isVolunteerDataResetEnabled: boolean;
  areVatAccessCodesEnabled: boolean;
}

export const INITIAL_STATE: IRefData = {
  appSettings: {
    programYear: 0,
    areVolAppNextYearRegistrationsEnabled: false,
    isVolunteerDataResetEnabled: false,
    areVatAccessCodesEnabled: false,
  },
  isLoading: false,
  hasBeenRequested: false,
  genders: [],
};

export enum EGender {
  M,
  F,
}

export enum EGenderMap {
  M = 'Boy',
  F = 'Girl',
}

export enum DonationEntityTypeId {
  UNSPECIFIED = 1,
  PERSON = 2,
  ORGANIZATION = 3,
}

export const refDataReducer = (
  state = INITIAL_STATE,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case GET_REF_DATA.TRIGGER:
      return { ...state, isLoading: true, hasBeenRequested: true };
    case GET_REF_DATA.SUCCESS:
      return { ...state, ...payload };
    case GET_REF_DATA.FAILURE:
      return state;
    case GET_REF_DATA.FULFILL:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
