import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import { getChildrenReduxState, ON_ADD_ITEM_TO_BASKET, ON_CLEAR_BASKET, ON_SET_CHECKOUT_MODE } from '../../pages/children';
import { TextField } from '@material-ui/core';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { CheckoutMode, ROUTES } from '../../constants';
import { OldButton } from '../common';
import { getAuthReduxState } from '../../pages/auth';
import { toastError } from '../../utils';
import { push } from 'connected-react-router';


export interface IProps {
  isVisible: boolean;
  setIsVisible: VoidFunction;
}

export const ExpressSponsorshipModal: React.FC<IProps> = ({ isVisible, setIsVisible }) => {

      const dispatch = useDispatch();
      const { basket } = useSelector(getChildrenReduxState);

      const INT_GREATER_THAN_ZERO_REGEX = /^$|^[1-9]\d*$/;  
      const { orgInfo } = useSelector(getAuthReduxState);

      const { childDonationAmount } = orgInfo;

      
      const [bulkNumChildren, setBulkNumChildren] = useState('');
      const [bulkDonationTotal, setBulkDonationTotal] = useState('');

      const onSponsorXChildrenSubmit = () => {
        if (parseInt(bulkDonationTotal) < childDonationAmount) {
          toastError(`Please enter a total of $${childDonationAmount} or more.`);
          return;
        }
        dispatch(
          ON_ADD_ITEM_TO_BASKET({
            data: { bulkDonationTotal, id: 0 },
          })
        );
    
        const url = ROUTES.REVIEW_BASKET.path;
        dispatch(push(url));
        dispatch(ON_SET_CHECKOUT_MODE(CheckoutMode.BULK_DONATION));
        setIsVisible();
      };
    
      return (
        <Modal
          isOpen={isVisible}
          className="checkout-mode-modal"
          backdrop
        >
          <div className="checkout-mode-options-modal parentDiv">
            <h2>How many children would you like to sponsor?</h2>
            <h4>For {orgInfo.orgName}</h4>
            <div className="detail-group">
              <div className="detail-section">
                <div className="option-group">
                  <label className="label-sponsor">Number of Children</label>{' '}
                  <TextField
                    value={bulkNumChildren}
                    className="textField"
                    label=" "
                    variant="standard"
                    focused
                    onChange={(e) => {
                      if (INT_GREATER_THAN_ZERO_REGEX.test(e.target.value)) {
                        setBulkNumChildren(e.target.value);
                        setBulkDonationTotal(
                          Math.floor(
                            orgInfo.childDonationAmount *
                              parseInt(e.target.value || '0')
                          ) + ''
                        );
                      }
                    }}
                  />
                  <label className="label-sponsor">
                    &nbsp;&nbsp;= &nbsp;&nbsp;
                  </label>{' '}
                  <label className="label-dollar">$</label>
                  <TextField
                    value={bulkDonationTotal}
                    className="textField"
                    label=" "
                    variant="standard"
                    focused
                    onChange={(e) => {
                      if (INT_GREATER_THAN_ZERO_REGEX.test(e.target.value)) {
                        setBulkDonationTotal(e.target.value);
                        setBulkNumChildren(
                          Math.floor(
                            parseInt(e.target.value || '0') /
                              orgInfo.childDonationAmount
                          ) + ''
                        );
                      }
                    }}
                  />
                </div>
                <div className="btn-submit">
                  <div className="icon-back">
                    <IoMdArrowRoundBack
                      onClick={() => {
                    if (basket.length > 0) {
                      dispatch(ON_CLEAR_BASKET());
                      dispatch(
                        ON_SET_CHECKOUT_MODE(CheckoutMode.SPECIFIC_CHILDREN)
                      );
                      setIsVisible();
                    }
                    dispatch(
                      ON_SET_CHECKOUT_MODE(CheckoutMode.SPECIFIC_CHILDREN)
                    );
                    setIsVisible();
                  }}
                />
              </div>
              <OldButton
                text="Submit"
                className="submit"
                onClick={onSponsorXChildrenSubmit}
              >
                Submit
              </OldButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ExpressSponsorshipModal