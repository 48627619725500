import { createSelector } from 'reselect';
import { IReduxState } from '../../redux/redux.interface';

const getAuthState = (state: IReduxState) => state.auth;

// eslint-disable-next-line import/prefer-default-export
export const getAuthReduxState = createSelector(
  [getAuthState],
  (state) => state
);
