import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SUB_HEADER_IMAGES } from '../../constants/images';

interface IProps {
  className?: string;
}

export const RandomChildImage: React.FC<IProps> = ({ className }) => {
  const headerImages = useMemo(
    () =>
      SUB_HEADER_IMAGES.map((SvgComponent, index) => (
        <SvgComponent key={index} className={className} />
      )),
    []
  );

  const [childImage, setChildImage] = useState<JSX.Element | null>(null);
  const hasGeneratedImage = useRef(false);

  useEffect(() => {
    const generateSubHeaderImageIndex = (): JSX.Element => {
      const randomIndex = Math.floor(Math.random() * headerImages.length);
      return headerImages[randomIndex];
    };

    const subHeaderImage = generateSubHeaderImageIndex();
    setChildImage(subHeaderImage);
    hasGeneratedImage.current = true;
  }, [headerImages]);

  return childImage;
};

export default RandomChildImage;
