import React from 'react';
import { OldButton } from '../button';
import { CardButton } from '../../common';

export interface IRBPFooterProps {
  backButtonText?: string;
  nextButtonText: string;
  donationTotal?: string;
  backButtonOnClick?: () => void;
  nextButtonOnClick?: () => void;
  disableNextButton?: boolean;
}

const RBPFooter: React.FC<IRBPFooterProps> = ({
  backButtonText = '',
  nextButtonText = '',
  donationTotal = '',
  nextButtonOnClick,
  backButtonOnClick,
  disableNextButton = false,
}) => {
  const renderDonationTotal = () =>
    donationTotal && (
      <div className="p-0 donation-total">
        <p className="donation-total">
          Total: <br/> {`$${donationTotal}`}
        </p>
      </div>
    );

  return (
    <>
      {renderDonationTotal()}
      <CardButton
        text={nextButtonText}
        className={`btn-size card-btn selection-btn`}
        type="submit"
        onClick={nextButtonOnClick}
        disabled={disableNextButton}
      />
      {backButtonText && (
        <div className="d-flex justify-content-center align-items-center rbp-footer">
          <OldButton
            text={backButtonText}
            className="back-btn"
            type="button"
            onClick={backButtonOnClick}
          />
        </div>
      )}
    </>
  );
};

export default RBPFooter;
