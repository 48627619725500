import React from 'react';
import {
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputProps,
} from 'reactstrap';
import { WrappedFieldProps } from 'redux-form';

// @ts-ignore
export interface ICustomInputFieldProps extends WrappedFieldProps, InputProps {
  size: string;
  icon: string;
}

const CustomInputField: React.FC<ICustomInputFieldProps> = ({
  input,
  size,
  className = '',
  type,
  readonly,
  disabled,
  icon,
  placeholder,
  maxLength,
  meta: { touched, error, warning },
}) => {
  const inValid = !!(error && touched);
  return (
    <InputGroup size={size} className={`${className}`}>
      <Input
        {...input}
        placeholder={placeholder}
        type={type}
        readOnly={readonly}
        disabled={disabled}
        maxLength={maxLength}
        invalid={inValid}
      />
      {icon && (
        <InputGroupAddon addonType="append">
          <InputGroupText>{icon}</InputGroupText>
        </InputGroupAddon>
      )}
      {touched &&
        ((error && <FormFeedback className="mt-0">{error}</FormFeedback>) ||
          (warning && <FormFeedback className="mt-0">{warning}</FormFeedback>))}
    </InputGroup>
  );
};
export default CustomInputField;
