import React from 'react';
import { useSelector } from 'react-redux';
import { ParseHtmlMessage } from '../../components/common';
import { getPaymentReduxState } from '../payment';
import { RandomChildImage } from '../../components/random-child-image/random-child-image';
import { OrnamentBlue, OrnamentRed } from '../../constants';

const Exit = () => {
  const { mail = '', contentionMessage = '' } =
    useSelector(getPaymentReduxState);

  return (
    <>
      <div className="exit-container">
        <RandomChildImage className="child-image" />
        <OrnamentRed className="red-ornament" />
        <OrnamentBlue className="blue-ornament" />
        <div className="exit-inner-container">
          <div className="heading">
            Thanks for making this a Christmas they'll never forget
          </div>
          <div className="exit-content">
            <p className="thanks-text">
              Your generosity today makes sure that every child who has a parent
              in prison feels loved and treasured this Christmas. With your
              help, we deliver a gift, the Gospel, and a note from their parent
              to give children a Christmas worth remembering.
              {!!contentionMessage && (
                <div>{ParseHtmlMessage(contentionMessage)}</div>
              )}
            </p>
            {mail && (
              <p className="email-content">
                A receipt for your donation has been sent to{' '}
                {mail || 'your email'}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Exit;
