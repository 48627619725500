import React from 'react';

export interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text: string;
  checked?: boolean;
}

// NOTE / TODO :: To be deprecated after 2024 redesign (name "card button" is innacurate; currently still used for navigation in various places)
const CardButton: React.FC<IButtonProps> = (props) => {
  const { text, disabled, onClick, checked, className, type } = props;
  return (
    <button
      type={type || 'button'}
      className={className}
      style={{
        background: checked ? 'white' : '',
        opacity: checked ? 0.5 : 1,
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default CardButton;
