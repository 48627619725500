import React, { CSSProperties, PropsWithChildren } from 'react';

interface IHeaderButtonProps {
  onClick: VoidFunction;
  isDisabled?: boolean;
  styleVariant?:
    | 'solid-primary'
    | 'transparent-primary'
    | 'transparent-light'
    | 'transparent-gray';
  styleOverride?: CSSProperties;
}

const Button: React.FC<PropsWithChildren<IHeaderButtonProps>> = ({
  onClick,
  isDisabled = false,
  styleVariant = 'solid-primary',
  styleOverride,
  children,
}) => {
  const styleClassNames = `pf-button pf-button--${styleVariant}`;

  return (
    <button
      style={styleOverride}
      className={styleClassNames}
      type="button"
      disabled={isDisabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
