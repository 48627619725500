import { createRoot } from 'react-dom/client';
import App from './app';
import AppProvider from './app-provider';
import 'bootstrap/dist/css/bootstrap.min.css';

const app = (
  <AppProvider>
    <App />
  </AppProvider>
);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(app);
