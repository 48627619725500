import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { isEqual } from 'lodash';
import { ApisauceInstance } from 'apisauce';

import { ParseHtmlMessage } from '../../components/common';
import { ON_SPONSOR_GIFT } from './payment.redux';
import Api from '../../services/api-caller';
import { formattedPaymentError, toastError } from '../../utils';
import { ROUTES, TEXT_CONSTANTS } from '../../constants';
import {
  ON_CLEAR_BASKET,
  ON_GET_CHILDREN,
} from '../children';

// @ts-ignore
export function* onSponsorGiftRequest(api, { payload }) {
  try {
    const { res = {} } = yield call(Api.callServer, api, payload);
    const { message = '' } = res || {};
    yield put(push(ROUTES.EXIT.path));
    yield put(ON_SPONSOR_GIFT.success({ mail: payload.mail, message }));
    yield put(ON_CLEAR_BASKET());
    yield put(ON_GET_CHILDREN());
  } catch ({ message, data = {} }) {
    let isPaymentError = false;
    if (
      data.error_key &&
      isEqual(data.error_key, TEXT_CONSTANTS.PAYMENT_ERROR_KEY)
    ) {
      isPaymentError = true;
      toastError(TEXT_CONSTANTS.PAYMENT_ERROR_MSG);
    } else {
      // @ts-ignore
      toastError(ParseHtmlMessage(formattedPaymentError(message)));
    }
    yield put(ON_SPONSOR_GIFT.failure(isPaymentError));
  } finally {
    yield put(ON_SPONSOR_GIFT.fulfill());
  }
}

export default (api: ApisauceInstance) => {
  const onSponsorGiftRequestApi = (data: any) => api.post('/Sponsors', data);

  return [
    takeEvery(
      // @ts-ignore
      ON_SPONSOR_GIFT.TRIGGER,
      onSponsorGiftRequest,
      onSponsorGiftRequestApi
    ),
  ];
};
