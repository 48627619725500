import { createRoutine } from 'redux-saga-routines';

export const ON_SPONSOR_GIFT = createRoutine('ON_SPONSOR_GIFT');
export const ON_SET_ACCESS_CODE = createRoutine('ON_SET_ACCESS_CODE');

export const INITIAL_STATE = {
  sponsorLoading: false,
  mail: '',
  contentionMessage: '',
  isPaymentError: false,
  accessCode: '',
};

export default (
  state = INITIAL_STATE,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case ON_SPONSOR_GIFT.TRIGGER:
      return {
        ...state,
        sponsorLoading: true,
        contentionMessage: '',
        isPaymentError: false,
      };
    case ON_SPONSOR_GIFT.SUCCESS:
      return {
        ...state,
        mail: payload.mail,
        contentionMessage: payload.message,
      };
    case ON_SPONSOR_GIFT.FAILURE:
      return { ...state, isPaymentError: payload };
    case ON_SPONSOR_GIFT.FULFILL:
      return { ...state, sponsorLoading: false };

    case ON_SET_ACCESS_CODE.TRIGGER:
      return { ...state, accessCode: payload };

    default:
      return state;
  }
};
