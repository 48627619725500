import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import { OldButton, CustomInputField } from '../../components/common';
import { required } from '../../utils';
import { getChildrenReduxState } from '../children';

const AuthForm: React.FC<InjectedFormProps<{ code: string }, {}>> = ({
  handleSubmit,
}) => {
  const { getChildrenLoading = false } = useSelector(getChildrenReduxState);

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="code"
        placeHolder="Enter Code"
        type="text"
        className="login-field"
        validate={[required]}
        component={CustomInputField}
      />
      <OldButton
        text="Submit"
        type="submit"
        className="red-btn mt-2"
        loading={getChildrenLoading}
      />
    </form>
  );
};

export default reduxForm<{ code: string }>({
  form: 'authForm',
})(AuthForm);
