import apisauce from 'apisauce';

const create = (baseURL: string) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    timeout: 60000,
  });

  return {
    ...api,
  };
};

export default {
  create,
};
