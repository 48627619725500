import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { Col, Label, Row } from 'reactstrap';
import PropsType from 'prop-types';
import { email, numericality, required } from 'redux-form-validators';

import { CustomInputField, ReduxFormDropdown } from '../../components/common';

import { getDropdownOptions } from '../../utils';
import { VALIDATE_MESSAGES } from '../../constants';
import { getStatesReduxState } from '../children/children';

const UserInformation = ({ label = '' }) => {
  const { states = [] } = useSelector(getStatesReduxState);

  return (
    <Row className="m-0">
      <Col className="px-1 py-0 text-left" lg="12">
        <Label className="mb-0">{label}</Label>
      </Col>
      <Col className="p-1" md="6">
        <Field
          name="first_name"
          size="sm"
          placeholder="First Name"
          type="text"
          validate={[required({ msg: VALIDATE_MESSAGES.REQUIRED })]}
          component={CustomInputField}
        />
      </Col>
      <Col className="p-1" md="6">
        <Field
          name="last_name"
          size="sm"
          placeholder="Last Name"
          type="text"
          validate={[required({ msg: VALIDATE_MESSAGES.REQUIRED })]}
          component={CustomInputField}
        />
      </Col>
      <Col className="p-1" md="12">
        <Field
          name="mail"
          size="sm"
          placeholder="Email Address"
          type="text"
          validate={[
            required({ msg: VALIDATE_MESSAGES.REQUIRED }),
            email({ msg: VALIDATE_MESSAGES.EMAIL }),
          ]}
          component={CustomInputField}
        />
      </Col>
      <Col className="p-1" md="12">
        <Field
          name="address"
          size="sm"
          placeholder="Address"
          type="text"
          validate={[required({ msg: VALIDATE_MESSAGES.REQUIRED })]}
          component={CustomInputField}
        />
      </Col>
      <Col className="p-1" md="12">
        <Field
          name="state"
          size="sm"
          placeholder="State"
          options={getDropdownOptions(states, 'states')}
          validate={[required({ msg: VALIDATE_MESSAGES.REQUIRED })]}
          component={ReduxFormDropdown}
        />
      </Col>
      <Col className="p-1" md="6">
        <Field
          name="city"
          size="sm"
          placeholder="City"
          type="text"
          validate={[required({ msg: VALIDATE_MESSAGES.REQUIRED })]}
          component={CustomInputField}
        />
      </Col>
      <Col className="p-1" md="6">
        <Field
          name="zip"
          size="sm"
          placeholder="Zip Code"
          type="text"
          validate={[
            required({ msg: VALIDATE_MESSAGES.REQUIRED }),
            numericality({ int: true, msg: VALIDATE_MESSAGES.ZIP }),
          ]}
          component={CustomInputField}
        />
      </Col>
    </Row>
  );
};

UserInformation.propsType = {
  label: PropsType.string.isRequired,
};

export default UserInformation;
