import { isEmpty, map, uniq } from 'lodash';
import { ICounty, IState } from '../types';

export const getDropdownOptions = (
  options: Array<IState> | Array<ICounty>,
  key: string
) => {
  if (isEmpty(options)) {
    return [];
  }

  return Object.freeze(
    map(options, (item = { id: -1, name: '', sponsorKids: 0 }) => {
      const itemName = item.name || '';
      if (key === 'states') {
        const name =
          itemName.charAt(0).toUpperCase() + itemName.slice(1).toLowerCase() ||
          itemName;
        return {
          label: `${name} (${(item as IState).shortCode})`,
          value: String(item.id),
          ...item,
        };
      }

      if (key === 'counties') {
        return { label: itemName, value: String(item.id), ...item };
      }

      return { label: '', value: '' };
    })
  );
};

export const getChildAge = (age: number = 0) => {
  if (!age) {
    return `Age: ?`;
  }
  return `Age: ${age}`;
};

export const formattedPaymentError = (error: string) => {
  if (isEmpty(error)) {
    return '';
  }

  const splitStr = error.split(/\.,/g);
  let tempStr: string[] = [];
  for (let index = 0; index < splitStr.length; index += 1) {
    let temp = splitStr[index].trim();
    const isMonthExp = temp.includes('card_expiration_month::');
    const isYearExp = temp.includes('card_expiration_year::');
    if (isMonthExp || isYearExp) {
      temp = `The credit card you have entered has expired.`;
    } else {
      const tempIndex = temp.indexOf('::');
      if (tempIndex > -1) {
        temp = temp.includes('.')
          ? temp.substring(tempIndex + 2)
          : `${temp.substring(tempIndex + 2)}.`;
      } else {
        temp = temp.includes('.') ? temp : `${temp}.`;
      }
    }
    tempStr.push(temp);
  }
  tempStr = uniq(tempStr);
  return `${tempStr.join('\r\n').replace(new RegExp(/::/g), ': ')}`;
};
