import React, { useState } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Col, Label, Row, Modal, ModalBody, FormFeedback } from 'reactstrap';
import { BsExclamationCircle } from 'react-icons/bs';
import { length, numericality, required } from 'redux-form-validators';
import cvvImage from '../../assets/images/cc-info-pic.png';

import {
  ReduxFormDropdown,
  CustomInputField,
  RBPFooter,
} from '../../components/common';
import {
  accountMatch,
  checkCombineExpiry,
  checkValidMonthField,
} from '../../utils';
import UserInformation from './userInformation';
import { VALIDATE_MESSAGES } from '../../constants';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getAuthReduxState } from '../auth';
import { DonationEntityTypeId } from '../../redux/refdata.redux';

export interface IPaymentFormProps {
  total: number;
  showCCInputs: boolean;
  onHandleBack: () => void;
  isPaymentError?: boolean;
}

const PaymentForm: React.FC<
  IPaymentFormProps & InjectedFormProps<{}, IPaymentFormProps>
> = ({
  handleSubmit,
  total,
  showCCInputs,
  onHandleBack,
  isPaymentError = false,
}) => {
  const [shouldShowCvvModal, setShouldShowCvvModal] = useState(false);

  const { orgInfo } = useSelector(getAuthReduxState);

  const { isNationalOrg } = orgInfo;

  const renderRadioGroup = ({ input, meta: { touched, error }, ...rest }) => {
    const radioGroupClassName = touched && error ? 'is-invalid' : '';

    return (
      <>
        <FormControl>
          <RadioGroup
            {...input}
            {...rest}
            value={input.value}
            onChange={(event, value) => input.onChange(value)}
            className={radioGroupClassName}
          >
            <FormControlLabel
              value={DonationEntityTypeId.PERSON.toString()}
              control={<Radio />}
              label="I am giving as an individual."
            />
            <FormControlLabel
              value={DonationEntityTypeId.ORGANIZATION.toString()}
              control={<Radio />}
              label="I am giving collectively for the church/organization."
            />
          </RadioGroup>
        </FormControl>
        {touched && error && (
          <FormFeedback className="mt-0">{error}</FormFeedback>
        )}
      </>
    );
  };

  const _renderCCInputs = () => {
    return (
      <div className="payment-form mt-4 mb-5 cc-content">
        <Col className="content-left">
          <Row className="m-0">
            <Col className="px-1 py-0 text-left">
              <Label className="mb-0">Credit Card Info</Label>
            </Col>
            <Col className="p-1" md="12">
              <Field
                name="card_number"
                size="sm"
                placeholder="Card Number"
                type="text"
                validate={[
                  required({ msg: VALIDATE_MESSAGES.REQUIRED }),
                  length({ msg: VALIDATE_MESSAGES.CC, in: [13, 19] }),
                  numericality({ msg: VALIDATE_MESSAGES.CC, int: true }),
                ]}
                component={CustomInputField}
              />
            </Col>
            <Col className="p-1" md="4">
              <Field
                name="card_cvv"
                size="sm"
                placeholder="cvc/cvv"
                type="text"
                validate={[
                  required({ msg: VALIDATE_MESSAGES.REQUIRED }),
                  length({ msg: VALIDATE_MESSAGES.CVC, in: [3, 4] }),
                  numericality({ msg: VALIDATE_MESSAGES.CVC, int: true }),
                ]}
                component={CustomInputField}
              />
              <BsExclamationCircle
                size="13"
                className="exclamation-mark"
                onClick={() => setShouldShowCvvModal(true)}
              />
              <Modal
                isOpen={shouldShowCvvModal}
                toggle={() => setShouldShowCvvModal(false)}
                className="cvv-modal"
                backdrop
              >
                <ModalBody>
                  <img className="cvv-image" src={cvvImage} alt="CVV Helper" />
                </ModalBody>
              </Modal>
            </Col>
            <Col className="p-1" md="4">
              <Field
                name="card_expiration_month"
                size="sm"
                placeholder="Month"
                type="text"
                validate={[
                  required({ msg: VALIDATE_MESSAGES.REQUIRED }),
                  checkValidMonthField,
                ]}
                component={CustomInputField}
              />
            </Col>
            <Col className="p-1" md="4">
              <Field
                name="card_expiration_year"
                size="sm"
                placeholder="Year"
                type="text"
                validate={[
                  required({ msg: VALIDATE_MESSAGES.REQUIRED }),
                  length({ msg: VALIDATE_MESSAGES.YEAR, min: 4, max: 4 }),
                  numericality({ msg: VALIDATE_MESSAGES.YEAR, int: true }),
                  checkCombineExpiry,
                ]}
                component={CustomInputField}
              />
            </Col>
          </Row>
        </Col>
        <Col className="content-right">
          <UserInformation label="CARDHOLDER INFO" />
        </Col>
      </div>
    );
  };

  const _renderACHInputs = () => {
    return (
      <div className="payment-form mt-4 mb-5 ach-content">
        <Col className="content-left" lg="6">
          <Row className="m-0">
            <Col className="px-1 py-0 text-left" lg="12">
              <Label className="mb-0">Bank Account Info</Label>
            </Col>
            <Col className="p-1" lg="12">
              <Field
                name="routing_number"
                size="sm"
                placeholder="Routing Number"
                type="text"
                validate={[
                  required({ msg: VALIDATE_MESSAGES.REQUIRED }),
                  numericality({
                    msg: VALIDATE_MESSAGES.ROUTING_NUM,
                    int: true,
                  }),
                  length({
                    msg: VALIDATE_MESSAGES.ROUTING_NUM,
                    min: 9,
                    max: 9,
                  }),
                ]}
                component={CustomInputField}
              />
            </Col>
            <Col className="p-1" lg="12">
              <Field
                name="account_number"
                size="sm"
                placeholder="Account Number"
                type="text"
                validate={[
                  required({ msg: VALIDATE_MESSAGES.REQUIRED }),
                  numericality({
                    msg: VALIDATE_MESSAGES.ACCOUNT_NUM,
                    int: true,
                  }),
                ]}
                component={CustomInputField}
              />
            </Col>
            <Col className="p-1" lg="12">
              <Field
                name="confirm_account_number"
                size="sm"
                placeholder="Confirm Account Number"
                type="text"
                validate={[
                  required({ msg: VALIDATE_MESSAGES.REQUIRED }),
                  numericality({
                    msg: VALIDATE_MESSAGES.ACCOUNT_NUM,
                    int: true,
                  }),
                  accountMatch,
                ]}
                component={CustomInputField}
              />
            </Col>
            <Col className="p-1" lg="12">
              <Field
                name="account_type"
                size="sm"
                placeholder="Account Type"
                type="text"
                options={[
                  { label: 'Checking', value: 'checking' },
                  { label: 'Savings', value: 'savings' },
                ]}
                validate={[required({ msg: VALIDATE_MESSAGES.REQUIRED })]}
                component={ReduxFormDropdown}
              />
            </Col>
            <Col className="p-1" lg="12">
              <Field
                name="bank_name"
                size="sm"
                placeholder="Bank Name"
                type="text"
                validate={[required({ msg: VALIDATE_MESSAGES.REQUIRED })]}
                component={CustomInputField}
              />
            </Col>
            <Col className="p-1" lg="12">
              <Field
                name="account_name"
                size="sm"
                placeholder="Account Name"
                type="text"
                validate={[required({ msg: VALIDATE_MESSAGES.REQUIRED })]}
                component={CustomInputField}
              />
            </Col>
          </Row>
        </Col>
        <Col className="content-right" lg="6">
          <UserInformation label="ACCOUNT HOLDER INFO " />
        </Col>
      </div>
    );
  };

  const _renderDonationEntityRadioGroup = () => {
    return (
      <div className="payment-form mb-5">
        <Row className="card-info">
          <Col className="card-info-div" md="12">
            <Field
              name="donation_entity_type_id"
              component={renderRadioGroup}
              validate={[required({ msg: VALIDATE_MESSAGES.REQUIRED })]}
            />
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      {showCCInputs && _renderCCInputs()}
      {!showCCInputs && _renderACHInputs()}
      {!isNationalOrg && _renderDonationEntityRadioGroup()}
      <RBPFooter
        backButtonOnClick={onHandleBack}
        backButtonText="RETURN TO DONATION"
        nextButtonText="CONFIRM PAYMENT"
        disableNextButton={isPaymentError}
        donationTotal={total.toFixed?.(2)}
      />
    </form>
  );
};

export default reduxForm<{}, IPaymentFormProps>({
  form: 'paymentForm',
})(PaymentForm);
