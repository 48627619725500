/* eslint-disable react/button-has-type */
import React from 'react';

import Loading from '../loading';

export interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text: string;
  loading?: boolean;
}

// NOTE & TODO :: Will be fully replaced at the end of VAT 2024 redesign
// PF-3773: https://dev.azure.com/prisonfellowship/IT%20Initiatives/_workitems/edit/3773
const OldButton: React.FC<IButtonProps> = (props) => {
  const {
    id = '',
    className = '',
    color = 'primary',
    text,
    loading = false,
    disabled,
    onClick,
    ...otherProps
  } = props;
  return (
    <button
      id={id}
      color={color}
      {...otherProps}
      className={`button  ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {loading ? <Loading className="button-loading" /> : text}
    </button>
  );
};

export default OldButton;
