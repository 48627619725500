/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { find, forEach, isEmpty, map } from 'lodash';
import { Modal, ModalBody } from 'reactstrap';
import { RouteComponentProps } from 'react-router';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { OldButton, Loading as LoadingProgress } from '../../components/common';
import { ChildCard, ChildFilter } from '../../components';
import {
  ON_ADD_ITEM_TO_BASKET,
  ON_CLEAR_BASKET,
  ON_GET_CHILDREN,
  ON_REMOVE_ITEM_FROM_BASKET,
  ON_SET_CHECKOUT_MODE,
  ON_SET_FILTERS,
  ON_SHOW_EMPTY_SEARCH_MODAL,
} from './children.redux';
import { getChildrenReduxState } from './children.selector';
import { getAuthReduxState } from '../auth';
import {
  checkBasketLimit,
  Env,
  isToastActive,
  toastInfo,
  updateToast,
  useWindowDimensions,
} from '../../utils';
import {
  ALERT_MESSAGE,
  CheckoutMode,
  CHILD_LIST_DIV_ID,
  DEFAULT_ORG_FILTERS,
  DEFAULT_PAGINATION_PARAMS,
  layoutBreakpoints,
} from '../../constants';
import { IGift, IObj } from '../../types';
import { EGender } from '../../redux/refdata.redux';
import { TextField } from '@material-ui/core';
import { IReduxState } from '../../redux/redux.interface';
import { createSelector } from 'reselect';
import { GET_STATES_DATA } from '../../redux/states.redux';
import PfButton from '../../components/common/button/pf-button';

const LAST_CHILD_CARD_IN_VIEW_ID = 'last-child-card-in-view';
const INT_GREATER_THAN_ZERO_REGEX = /^$|^[1-9]\d*$/;
const MESSAGE_TRUNCATE_LENGTH_DESKTOP = 80;
const MESSAGE_TRUNCATE_LENGTH_MOBILE = 40;

export const getStatesState = (state: IReduxState) => state.statesData;

export const getStatesReduxState = createSelector(
  [getStatesState],
  (state) => state
);

const Children: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();

  const {
    children = [],
    getChildrenLoading = true,
    defaultSearchLoading = true,
    basketCount,
    basket = [],
    showMore = false,
    isResultEmpty = false,
    isSearch = false,
    showNationalThresholdMessage = false,
    allSponsored = false,
    filters = DEFAULT_ORG_FILTERS,
  } = useSelector(getChildrenReduxState);
  const { orgInfo, loading: orgLoading } = useSelector(getAuthReduxState);

  const { isNationalOrg, noOfGiftsAllowed } = orgInfo;

  const [childData, setChildData] = useState<Array<IGift>>([]);
  const [isApplyFilter, setApplyFilter] = useState(false);
  const [isEmptySearch, setEmptySearch] = useState(false);

  const bucketFullMessRef = React.useRef<string | number | null>(null);
  const previousLastChildCardIdInView = React.useRef<number | undefined>();
  const lastChildSelectionId = React.useRef<number | undefined>();

  useEffect(() => {
    if (!getChildrenLoading) dispatch(GET_STATES_DATA({}));
  }, [getChildrenLoading]);

  useEffect(() => {
    if (isEmpty(children)) {
      dispatch(ON_CLEAR_BASKET());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (isNationalOrg && isEmpty(children) && !orgLoading) {
      dispatch(ON_GET_CHILDREN());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgLoading]);

  useEffect(() => {
    if (isResultEmpty) {
      setApplyFilter(false);
      dispatch(ON_SHOW_EMPTY_SEARCH_MODAL(false));
      setEmptySearch(isResultEmpty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResultEmpty]);

  useEffect(() => {
    if (!isEmpty(children)) {
      let tempGifts: Array<IGift> = [];
      if (!isEmpty(basket)) {
        forEach(basket, (item) => {
          tempGifts = map(children, (data) => {
            if (data.id === item.id) {
              data.checked = item.checked;
            }
            return data;
          });
        });
        setChildData(tempGifts);
      } else {
        setChildData(children);
      }
    } else {
      setChildData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, basket, filters]);

  useEffect(() => {
    const hasData = !!childData?.length;
    const hasNewData =
      hasData &&
      previousLastChildCardIdInView.current !==
        childData[childData.length - 1].id;

    const isShowMeMore =
      hasNewData &&
      !!previousLastChildCardIdInView.current &&
      !lastChildSelectionId.current;
    if (isShowMeMore) {
      setTimeout(() => {
        window.scrollTo(
          0,
          // @ts-ignore
          document.getElementById(LAST_CHILD_CARD_IN_VIEW_ID)?.offsetTop -
            // @ts-ignore
            document.getElementById('HEADER_DIV_ID')?.offsetHeight
        );
      }, 0);
    }
  }, [childData, filters]);

  const getSearchFilters = () => {
    const {
      ageRange = [],
      isGirl = false,
      isBoy = false,
      state = '',
      county_id,
    } = filters || {};
    let payload: IObj = {
      min_age: ageRange[0],
      max_age: ageRange[1],
      state,
      county_id,
    };

    let gender = '';
    if (isGirl && !isBoy) {
      gender = EGender[EGender.F];
    } else if (!isGirl && isBoy) {
      gender = EGender[EGender.M];
    }
    if (!isEmpty(gender)) {
      payload = { ...payload, gender };
    }

    return payload;
  };

  const onChangeHandler: <T = typeof DEFAULT_ORG_FILTERS>(
    inputValue: T[keyof T],
    key: keyof T
  ) => void = (inputValue, key) => {
    (filters as any)[key] = inputValue;
    if (key === 'state') (filters as any)['county_id'] = undefined;

    dispatch(ON_SET_FILTERS(filters));
    onSubmitSearch();
  };

  const onSubmitSearch = () => {
    setApplyFilter(true);
    dispatch(
      ON_GET_CHILDREN({ ...getSearchFilters(), ...DEFAULT_PAGINATION_PARAMS })
    );
  };

  const chooseChild = (event: any, id: number) => {
    event.stopPropagation();
    const selectedChildInfo = find(childData, (data) => data.id === id);
    if (!selectedChildInfo) return;
    lastChildSelectionId.current = selectedChildInfo?.id;

    if (
      !selectedChildInfo.checked && // if not checked we are going to mark that selected
      isNationalOrg && // only limit selectable children for national org
      checkBasketLimit(basketCount + 1, noOfGiftsAllowed || 5)
    ) {
      if (isToastActive(bucketFullMessRef)) {
        updateToast(bucketFullMessRef);
      } else {
        bucketFullMessRef.current = toastInfo(
          `${ALERT_MESSAGE.BASKET_FULL}${noOfGiftsAllowed}.`
        );
      }
      return;
    }
    const childrenMap = map(childData, (data) => {
      if (id === data.id) {
        data.checked = !data.checked;
      }
      return data;
    });

    if (selectedChildInfo.checked) {
      dispatch(
        ON_ADD_ITEM_TO_BASKET({
          data: selectedChildInfo,
        })
      );
    } else {
      dispatch(
        ON_REMOVE_ITEM_FROM_BASKET({
          data: selectedChildInfo,
        })
      );
    }

    setChildData(childrenMap);
  };

  const onLoadMoreData = () => {
    previousLastChildCardIdInView.current = childData[childData.length - 1].id;
    lastChildSelectionId.current = undefined;
    let payload: IObj = { isMoreRecord: true };
    if (isApplyFilter) {
      payload = { ...payload, ...getSearchFilters() };
    }
    dispatch(ON_GET_CHILDREN({ ...payload }));
  };

  const isLoading = getChildrenLoading || defaultSearchLoading;

  return (
    <>
      {defaultSearchLoading && <LoadingProgress fullPage />}
      <div className="children-and-filter-container">
        <ChildFilter filters={filters} onChange={onChangeHandler} />
        <div id={CHILD_LIST_DIV_ID}></div>
        <ChildrenList
          isLoading={isLoading}
          isApplyFilter={isSearch}
          shouldNotShowChildren={showNationalThresholdMessage || allSponsored}
          showMore={showMore}
          onLoadMoreData={onLoadMoreData}
          childData={childData}
          chooseChild={chooseChild}
          previousLastChildCardIdInView={previousLastChildCardIdInView}
        />
      </div>
      <ChildrenModal
        isEmptySearch={isEmptySearch}
        setEmptySearch={setEmptySearch}
      >
        <EmptySearchModal setEmptySearch={setEmptySearch}></EmptySearchModal>
      </ChildrenModal>
    </>
  );
};

const ChildrenList = ({
  isLoading,
  isApplyFilter,
  shouldNotShowChildren,
  showMore,
  onLoadMoreData,
  childData,
  chooseChild,
  previousLastChildCardIdInView,
}) => {
  const { checkoutMode } = useSelector(getChildrenReduxState);

  const isCheckoutModeModalOpen = checkoutMode === CheckoutMode.BULK_DONATION;

  if (isLoading)
    return (
      <div
        className={`children-container position-relative ${
          isCheckoutModeModalOpen && 'result-fade'
        }`}
      >
        <h2>Loading...</h2>
      </div>
    );
  if (isEmpty(childData) && isApplyFilter)
    return (
      <div
        className={`children-container position-relative ${
          isCheckoutModeModalOpen && 'result-fade'
        }`}
      >
        <h2>No Children Found</h2>
      </div>
    );
  return shouldNotShowChildren ? (
    <AllChildrenSponsored />
  ) : (
    <div
      className={`children-container position-relative ${
        isCheckoutModeModalOpen && 'result-fade'
      }`}
    >
      <div className="justify-content-center children-list-container flex-wrap">
        <ChildCards
          childData={childData}
          chooseChild={chooseChild}
          previousLastChildCardIdInView={previousLastChildCardIdInView}
        />
      </div>
      {showMore && (
        <PfButton
          onClick={onLoadMoreData}
          styleVariant="transparent-gray"
          styleOverride={{ width: '100%' }}
        >
          SHOW ME MORE
        </PfButton>
      )}
    </div>
  );
};
const ChildCards = ({
  childData,
  chooseChild,
  previousLastChildCardIdInView,
}) => {
  const { width } = useWindowDimensions();

  let messageTruncateLength = MESSAGE_TRUNCATE_LENGTH_MOBILE;
  if (width >= layoutBreakpoints.M) {
    messageTruncateLength = MESSAGE_TRUNCATE_LENGTH_DESKTOP;
  }

  return childData.map(({ id, child, message, checked }) => {
    const childCardComponent = (
      <ChildCard
        key={`child-${id}`}
        id={
          previousLastChildCardIdInView.current === id
            ? LAST_CHILD_CARD_IN_VIEW_ID
            : id
        }
        child={child}
        parentMessage={`${
          child.inmateMessage.length > messageTruncateLength
            ? child.inmateMessage.substring(0, messageTruncateLength) + '...'
            : child.inmateMessage
        }`}
        checked={checked}
        chooseChild={chooseChild}
        detailedMessage={child.inmateMessage}
      />
    );
    return childCardComponent;
  });
};

const EmptySearchModal = ({ setEmptySearch }) => (
  <>
    <div className="empty-modal-message">{ALERT_MESSAGE.NO_SEARCH_RESULTS}</div>
    <OldButton
      onClick={() => setEmptySearch(false)}
      text="Close"
      className="error-modal-button "
    />
  </>
);

const CheckoutModeModal = ({
  isCheckoutModeModalOpen,
  orgInfo,
  sponsorXChildrenInput,
  setSponsorXChildrenInput,
  setSponsorNXChildrenInput,
  sponsorNXChildrenInput,
  onSponsorXChildrenSubmit,
}) => {
  const dispatch = useDispatch();

  const { basket } = useSelector(getChildrenReduxState);

  return (
    <Modal
      isOpen={isCheckoutModeModalOpen}
      className="checkout-mode-modal"
      backdrop
    >
      <div className="checkout-mode-options-modal parentDiv">
        <h2>How many children would you like to sponsor?</h2>
        <h4>For {orgInfo.orgName}</h4>
        <div className="detail-group">
          <div className="detail-section">
            <div className="option-group">
              <label className="label-sponsor">Number of Children</label>{' '}
              <TextField
                value={sponsorXChildrenInput}
                className="textField"
                label=" "
                variant="standard"
                focused
                onChange={(e) => {
                  if (INT_GREATER_THAN_ZERO_REGEX.test(e.target.value)) {
                    setSponsorXChildrenInput(e.target.value);
                    setSponsorNXChildrenInput(
                      Math.floor(
                        orgInfo.childDonationAmount *
                          parseInt(e.target.value || '0')
                      ) + ''
                    );
                  }
                }}
              />
              <label className="label-sponsor">
                &nbsp;&nbsp;= &nbsp;&nbsp;
              </label>{' '}
              <label className="label-dollar">$</label>
              <TextField
                value={sponsorNXChildrenInput}
                className="textField"
                label=" "
                variant="standard"
                focused
                onChange={(e) => {
                  if (INT_GREATER_THAN_ZERO_REGEX.test(e.target.value)) {
                    setSponsorNXChildrenInput(e.target.value);
                    setSponsorXChildrenInput(
                      Math.floor(
                        parseInt(e.target.value || '0') /
                          orgInfo.childDonationAmount
                      ) + ''
                    );
                  }
                }}
              />
            </div>
            <div className="btn-submit">
              <div className="icon-back">
                <IoMdArrowRoundBack
                  onClick={() => {
                    if (basket.length > 0) {
                      dispatch(ON_CLEAR_BASKET());
                      dispatch(
                        ON_SET_CHECKOUT_MODE(CheckoutMode.SPECIFIC_CHILDREN)
                      );
                    }
                    dispatch(
                      ON_SET_CHECKOUT_MODE(CheckoutMode.SPECIFIC_CHILDREN)
                    );
                  }}
                />
              </div>
              <OldButton
                text="Submit"
                className="submit"
                onClick={onSponsorXChildrenSubmit}
              >
                Submit
              </OldButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ChildrenModal = ({ isEmptySearch, setEmptySearch, children }) => (
  <Modal
    isOpen={isEmptySearch}
    toggle={() => setEmptySearch(false)}
    className="user-info-modal"
    backdrop
  >
    <ModalBody>{children}</ModalBody>
  </Modal>
);

const AllChildrenSponsored = () => (
  <div className="child-render-inner-container">
    <div className="col-md-12 col-sm-12 col-xs-12 no-record">
      <em>
        {' '}
        <span className="praise-the-lord">Praise the Lord!</span>{' '}
        <span className="all-children-sponsored">
          This organization’s children have all been sponsored.
        </span>
        <div className="check-national-org">
          Check out the{' '}
          <a
            className="unmatched-page"
            href={Env.REACT_APP_BASE}
            target="blank"
          >
            Unmatched Angel Tree Children page
          </a>{' '}
          to find more!
        </div>
      </em>
    </div>
  </div>
);

export default Children;
