import React, { PropsWithChildren } from 'react';

export interface ICardProps extends PropsWithChildren {
  className: string;
}

const Card: React.FC<ICardProps> = ({ className = '', children = null }) => (
  <div className={`${className} font-fam`}>{children}</div>
);

export default Card;
