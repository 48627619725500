import { put, takeLatest, take } from 'redux-saga/effects';
import { ApisauceInstance } from 'apisauce';
import { isEmpty } from 'lodash';
import { ON_GET_ORGANIZATION_INFO } from '../pages/auth';
import { GET_REF_DATA } from '../redux/refdata.redux';

export function* startupSagas(
  // eslint-disable-next-line no-shadow
  { setHeader }: { setHeader: (key: string, value: string) => any },
  { key }: { key: string }
) {
  try {
    yield put(GET_REF_DATA());
    yield take(GET_REF_DATA.FULFILL);

    if (key === 'children') {
      const { location = { pathname: '' } } = window;
      const splitPathname = location.pathname?.split('/');
      const urlUUID = splitPathname[1] || '';
      if (!isEmpty(urlUUID)) {
        setHeader('publicid', urlUUID);
      }
      yield put(ON_GET_ORGANIZATION_INFO());
    }
  } catch ({ message }) {
    console.error(message);
  }
}

export default (api: ApisauceInstance) => {
  return [
    // @ts-ignore
    takeLatest('persist/REHYDRATE', startupSagas, { setHeader: api.setHeader }),
  ];
};
