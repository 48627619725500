/* eslint-disable no-param-reassign,camelcase,no-lonely-if */
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { replace } from 'connected-react-router';
import { ApisauceInstance } from 'apisauce';

import { ON_GET_CHILDREN } from './children.redux';
import Api from '../../services/api-caller';
import { redirectToStaticDonationSite, toastError } from '../../utils';
import {
  DEFAULT_PAGINATION_PARAMS,
  ROUTES,
  TEXT_CONSTANTS,
} from '../../constants';
import { ON_SET_ACCESS_CODE } from '../payment';
import { IGift } from '../../types';

const defaultMessages = [
  'Merry Christmas!!',
  'I love you',
  'I miss you',
  'Thinking of you always',
];

let messIndex = 0;

function populateMessages(gifts: Array<IGift>, reset = false) {
  if (reset) {
    messIndex = 0;
  }
  return gifts.map((gift) => {
    const giftMessage = gift.child.inmateMessage
      ? gift.child.inmateMessage
      : defaultMessages[messIndex];
    if (!gift.message) {
      gift.message = defaultMessages[messIndex];
      gift.child.inmateMessage = giftMessage;
      messIndex = (messIndex + 1) % defaultMessages.length;
    }
    return gift;
  });
}

// @ts-ignore
export function* onGetChildrenRequest(api, setHeader, { payload }): any {
  let isEmptySearch = false;
  try {
    const {
      code,
      caregiver_id,
      giftId,
      isNavigate = false,
      isMoreRecord = false,
      isEmptySearch: emptyResults = false,
      ...rest
    } = payload || {};
    const orgInfo = yield select(({ auth = {} }) => auth.orgInfo) || {};
    const persistedChildren = yield select(
      ({ children = {} }) => children.children
    ) || [];

    if (code) {
      setHeader('accesscode', code);
      yield put(ON_SET_ACCESS_CODE(code));
    }

    let reqData = {
      ...rest,
      populate: 'True',
      start: isMoreRecord
        ? persistedChildren.length || 0
        : DEFAULT_PAGINATION_PARAMS.start,
      limit: DEFAULT_PAGINATION_PARAMS.limit,
    };

    if (caregiver_id) {
      reqData = { ...reqData, caregiver_id };
    }

    const { res = {} } = yield call(Api.callServer, api, reqData);
    const {
      gifts = [],
      numRequestedGifts = 0,
      numDonatedGifts = 0,
      numUndonatedGifts = 0,
      isOrgProgramUnderMinThreshold = false,
    } = res || {};
    let showMore = true;

    const isSearch =
      reqData.min_age > 0 ||
      reqData.max_age < 18 ||
      !!reqData.gender ||
      reqData.start > 0 ||
      !!reqData.state ||
      !!reqData.county_id;
    if (isSearch && isEmpty(gifts)) {
      isEmptySearch = true;
      yield put(
        ON_GET_CHILDREN.success({
          isSearch: isSearch,
          children: [],
        })
      );
    }
    if (isOrgProgramUnderMinThreshold) {
      redirectToStaticDonationSite();
    }

    if (isNavigate) {
      yield put(replace(`/${orgInfo.publicId}/${ROUTES.CHILDREN.path}`));
    }

    const totalGiftsReturned = persistedChildren.length + gifts.length;
    if (
      totalGiftsReturned >=
        orgInfo.noOfGiftsAllowed *
          DEFAULT_PAGINATION_PARAMS.max_cart_multiple ||
      gifts.length < DEFAULT_PAGINATION_PARAMS.limit
    ) {
      showMore = false;
    }
    yield put(
      ON_GET_CHILDREN.success({
        children: populateMessages(gifts, !isMoreRecord),
        isSearch: isSearch,
        showMore,
        numRequestedGifts,
        numDonatedGifts,
        numUndonatedGifts,
      })
    );
  } catch ({ message = '' }) {
    toastError(`${message}`);
    yield put(
      ON_GET_CHILDREN.failure({
        message,
      })
    );
  } finally {
    yield put(ON_GET_CHILDREN.fulfill({ isEmptySearch, isSearch: true }));
  }
}

export default (api: ApisauceInstance) => {
  const { setHeader } = api;
  const onGetChildrenRequestApi = (data: any) => api.get('/Gifts', data);

  return [
    takeEvery(
      // @ts-ignore
      ON_GET_CHILDREN.TRIGGER,
      onGetChildrenRequest,
      onGetChildrenRequestApi,
      setHeader
    ),
  ];
};
