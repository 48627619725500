import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../services/api-caller';
import { ApisauceInstance } from 'apisauce';
import { ON_GET_STATE_COUNTY } from '../redux/counties.redux';

export function* onGetStateCountyRequest(api, { payload }) {
  try {
    const { res = {} } = yield call(Api.callServer, api, payload);
    yield put(ON_GET_STATE_COUNTY.success(res));
  } catch ({ message }) {
    yield put(ON_GET_STATE_COUNTY.failure(message));
  } finally {
    yield put(ON_GET_STATE_COUNTY.fulfill());
  }
}

export default (api: ApisauceInstance) => {
  const onGetStateCountyRequestApi = ({ state_id, ...rest }: any) => {
    if (state_id) return api.get(`/Counties/${state_id}`, rest);
    return {};
  };

  return [
    // @ts-ignore
    takeEvery(
      // @ts-ignore
      ON_GET_STATE_COUNTY.TRIGGER,
      onGetStateCountyRequest,
      onGetStateCountyRequestApi
    ),
  ];
};
