import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, map } from 'lodash';
import { Col, Row } from 'reactstrap';
import { RouteComponentProps } from 'react-router';
import { CheckoutMode, Gift1, Gift2, Gift3, OrnamentBlue, OrnamentRedShort, ROUTES } from '../../constants';
import {
  getChildrenReduxState,
  ON_REMOVE_ITEM_FROM_BASKET,
  ON_SET_CHECKOUT_MODE,
} from '../children';
import { getAuthReduxState } from '../auth';
import { useCalSum, useNavIfBasketEmpty } from '../../utils';
import { IGift } from '../../types';
import Button from '../../components/common/button/pf-button';

export interface IReviewBasketProps extends RouteComponentProps {}

const ReviewBasket: React.FC<IReviewBasketProps> = ({ history }) => {
  const dispatch = useDispatch();

  const { basket = [], checkoutMode } = useSelector(getChildrenReduxState);

  const { orgInfo } = useSelector(getAuthReduxState);
  const { childDonationAmount } = orgInfo;

  const isBulkDonation = checkoutMode === CheckoutMode.BULK_DONATION;
  const childrenPerSingleTag = 1

  useNavIfBasketEmpty(basket);
  const total = useCalSum(orgInfo, basket);

  const donationTotal = isBulkDonation ? basket?.[0]?.bulkDonationTotal : total.toFixed?.(2)

  const onRemoveFromBasket = (gift: IGift) => {
    if (
      (checkoutMode === CheckoutMode.SPECIFIC_CHILDREN && basket.length <= 1) ||
      checkoutMode === CheckoutMode.BULK_DONATION
    ) {
      dispatch(ON_SET_CHECKOUT_MODE(CheckoutMode.SPECIFIC_CHILDREN));
    }

    dispatch(
      ON_REMOVE_ITEM_FROM_BASKET({
        data: gift,
        isReviewBasket: true,
      })
    );
  };

  const renderGiftIcon = (id: any) => { // TODO: refactor into util function to dry with child-card
    try {
      let number = id;
      if (typeof id === 'string') {
        number = parseInt(id || '0', 10);
      }
      // @ts-ignore
      const index = number % 3;

      if (index === 0) {
        return <Gift1 className="" />;
      } else if (index === 2) {
        return <Gift2 className="" />;
      } else {
        return <Gift3 className="" />;
      }
    } catch (e) {
      return <Gift1 className="" />;
    }
  };

  const renderAutoSelect = (bulkDonationTotal) => {
    const bulkNumChildren = Math.floor(bulkDonationTotal / childDonationAmount);
    const randomGiftIconId = Math.floor(Math.random() * 3); // number of gift images

    return (
      <div className='child-donation-item-container'>
        <div>
          <Row className="child-donation-list-row" key={1}>
            {renderGiftIcon(randomGiftIconId)}
            <Col className="tag-item-column">
              <p>
                Donation
              </p>
            </Col>
            <Col className="children-per-tag-column">
              <p>
                {bulkNumChildren}
              </p>
            </Col>
            <Col className="price-column">
              <p className="">
                {bulkDonationTotal} 
              </p>
            </Col>
          </Row>
        </div>
        <button className='close-button-container'>
          <p 
            className='close-button'
            onClick={() => onRemoveFromBasket(bulkDonationTotal)}>
            x
          </p>
        </button>
      </div>
    );
  };
  const _renderRBPCardContent = () => {
    return (
      <>
        {isEmpty(basket) ? (
          <div className="empty-basket">Basket is empty!</div>
        ) : (
          <>
            <div className="child-donation-list-container">
              {map(basket, (item, key) => {
                const { child, bulkDonationTotal } = item || { child: {} };

                if (!child && bulkDonationTotal) {
                  return renderAutoSelect(bulkDonationTotal);
                } else if (!child) {
                  history.push(ROUTES.CHILDREN.path);
                  return;
                }

                return (
                  <div className='child-donation-item-container'>
                    <div>
                      <Row className="child-donation-list-row" key={key}>
                        {renderGiftIcon(child.id)}
                        <Col className="tag-item-column">
                          <p>
                            {child.firstName}
                          </p>
                        </Col>
                        <Col className="children-per-tag-column">
                          <p>
                            {childrenPerSingleTag}
                          </p>
                        </Col>
                        <Col className="price-column">
                          <p className="">
                            {`$${childDonationAmount || 25}`}
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <button className='close-button-container'>
                      <p 
                        className='close-button'
                        onClick={() => onRemoveFromBasket(item)}>
                        x
                      </p>
                    </button>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="review-basket-container">
        <div className="review-basket-header">
          <p>These kids will have a great Christmas, thanks to you!</p>
          <div className='ornament-container'>
            <div className='red-ornament'>
              <OrnamentRedShort/>
            </div>
            <div className='blue-ornament'>
              <OrnamentBlue/>
            </div>
          </div>
        </div>
        <div className='review-basket-tag-label-box'>
          <p className='review-basket-tag-label-box__label-one'>Item</p>
          <p className='review-basket-tag-label-box__label-two'>Number of <br/> Children</p>
          <p className='review-basket-tag-label-box__label-three'>Price</p>
        </div>
        <div className='review-basket-contents'>
          {_renderRBPCardContent()}
          <div className='review-basket-payment-box'>
            <p className='total-amount-text'>
              Total: <br/> {`$${donationTotal}`}
            </p>
            <Button styleOverride={{}} onClick={() => history.push(ROUTES.PAYMENT.path)}>Continue to Payment</Button>
            {!isBulkDonation && <p 
              className='add-child-text'
              onClick={() => history.push(ROUTES.CHILDREN.path)}
              >
              Add another child
            </p>}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewBasket;
