/* eslint-disable react/destructuring-assignment,no-lonely-if */
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, ModalBody } from 'reactstrap';
import { RouteComponentProps } from 'react-router';
import Header from '../components/header';
import { getChildrenReduxState, ON_CLEAR_BASKET } from '../pages/children';
import {
  ALERT_MESSAGE,
  BASKET_TIME_OUT_THRESHOLD_MS,
  ROUTES,
} from '../constants';
import { OldButton } from '../components/common';
import { useWindowUnload } from '../utils';
import SubHeader from '../components/sub-header';
import ExpressSponsorshipModal from '../components/sub-header/express-sponsorship-modal';

export interface IMainLayoutProps extends RouteComponentProps {
  children: ReactNode;
}

const MainLayout = (props: IMainLayoutProps) => {
  const dispatch = useDispatch();

  const { basket = [] } = useSelector(getChildrenReduxState);

  const [isCheckoutModeModalOpen, setIsCheckoutModeModalOpen] = useState(false);
  const [openSessionExpiryModal, setSessionExpiryModal] = useState(false);
  // eslint-disable-next-line no-undef
  const timer = useRef<NodeJS.Timeout | null>(null);

  let { pathname }: any = props.location;
  pathname = pathname ? pathname.split('/') : [];

  const currentRoutePath = pathname[2];

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    if (basket.length > 0) {
      const tempTimer = setTimeout(() => {
        dispatch(ON_CLEAR_BASKET());
        setSessionExpiryModal(true);
      }, BASKET_TIME_OUT_THRESHOLD_MS);
      timer.current = tempTimer;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basket]);

  useWindowUnload(basket);
  const onCloseSessionResetModal = () => {
    setSessionExpiryModal(false);
    window.location.reload();
  };

  const _renderSessionResetModalBody = () => {
    return (
      <>
        <div className="message">{ALERT_MESSAGE.SESSION_EXPIRE}</div>
        <OldButton
          text="Okay"
          className="red-btn mt-2"
          onClick={onCloseSessionResetModal}
        />
      </>
    );
  };

  return (
    <>
      <Header currentRoutePath={currentRoutePath} />
      {currentRoutePath === ROUTES.CHILDREN.path && (
        <SubHeader
          setIsExpressSponsorshipModalVisible={() =>
            setIsCheckoutModeModalOpen(true)
          }
        />
      )}
      <ExpressSponsorshipModal
        isVisible={isCheckoutModeModalOpen}
        setIsVisible={() => setIsCheckoutModeModalOpen(false)}
      />
      {props.children}

      <Modal
        isOpen={openSessionExpiryModal}
        className="user-info-modal"
        backdrop
      >
        <ModalBody>{_renderSessionResetModalBody()}</ModalBody>
      </Modal>
    </>
  );
};

export default MainLayout;
