import {
  expirationDate,
  expirationMonth,
  expirationYear,
} from 'card-validator';
import { VALIDATE_MESSAGES } from '../constants';

export const required = (value: string) =>
  value ? undefined : VALIDATE_MESSAGES.REQUIRED;

export const accountMatch = (value = '', { account_number = '' } = {}) =>
  !value || value === account_number
    ? undefined
    : VALIDATE_MESSAGES.ACCOUNT_MISMATCH;

export const checkCombineExpiry = (
  value = '',
  { card_expiration_month = '' } = {}
) => {
  const date =
    expirationDate({
      month: card_expiration_month,
      year: value,
    }) || {};
  return date.isPotentiallyValid && date.isValid
    ? ''
    : VALIDATE_MESSAGES.EXP_CARD;
};

export const validYear = (value = '') => {
  const year = expirationYear(value) || {};
  return year.isPotentiallyValid && year.isValid;
};

export const checkValidMonthField = (value = '') => {
  const month = expirationMonth(value) || {};
  return month.isPotentiallyValid && month.isValid
    ? null
    : VALIDATE_MESSAGES.MONTH;
};

export const checkBasketLimit = (basketCount: number, limit: number) => {
  if (limit === 0) {
    return false;
  }
  return basketCount > limit;
};
