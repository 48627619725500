import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { replace } from 'connected-react-router';
import { ROUTES } from '../constants';
import { addUnloadEvent, removeUnloadEvent } from './window';
import { IGift, IObj, IOrgInfo } from '../types';

export const useNavIfBasketEmpty = (basket: Array<IGift> = []) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isEmpty(basket)) {
      dispatch(replace(ROUTES.CHILDREN.path));
    }
  }, [basket, dispatch]);
};

export const useCalSum = (
  orgInfo: IOrgInfo | IObj = {},
  basket: Array<IGift> = []
) => {
  const [sum, setSum] = useState(0);
  useMemo(() => {
    if (!isEmpty(basket)) {
      const totalAmount = basket.length * orgInfo.childDonationAmount;
      setSum(totalAmount);
    }
  }, [basket, orgInfo]);

  return sum;
};

export const useWindowUnload = (basket: Array<IGift> | boolean) => {
  useEffect(() => {
    const hasBasket = basket === true || !isEmpty(basket);
    if (hasBasket) {
      addUnloadEvent();
    }
    return () => {
      if (hasBasket) {
        removeUnloadEvent();
      }
    };
  }, [basket]);
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

// NOTE :: Favor CSS media queries for styling wherever possible over this
export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
