import React from 'react';
import Select, { NamedProps, OptionsType } from 'react-select';
import { WrappedFieldProps } from 'redux-form';
import { ISelectObj } from '../../../types';

export interface IDropdownProps extends NamedProps<ISelectObj> {
  shouldHaveBlankOption?: boolean;
}

export const Dropdown: React.FC<IDropdownProps> = (props) => {
  const {
    className = '',
    onChange,
    options = [],
    autoFocus = false,
    isDisabled = false,
    isMulti = false,
    isSearchable = true,
    placeholder = '',
    value = null,
    shouldHaveBlankOption = false,
  } = props;

  const blankOptionText = typeof placeholder === 'string' ? placeholder : '-';
  const displayOptions = [
    ...(shouldHaveBlankOption && value
      ? [{ label: blankOptionText, value: null }]
      : []),
    ...options,
  ] as OptionsType<ISelectObj>;

  return (
    <Select
      onChange={onChange}
      isSearchable={isSearchable}
      placeholder={placeholder}
      options={displayOptions}
      autoFocus={autoFocus}
      isDisabled={isDisabled}
      isMulti={isMulti}
      className={`react-select ${className}`}
      value={value}
    />
  );
};

export interface IReduxFormDropdownProps
  extends WrappedFieldProps,
    NamedProps<React.ChangeEvent<any>> {}

export const ReduxFormDropdown: React.FC<IReduxFormDropdownProps> = ({
  input,
  className = '',
  options = [],
  isDisabled = false,
  isMulti = false,
  isSearchable = true,
  placeholder = '',
  name = '',
  meta: { error, touched },
}) => {
  return (
    <>
      <Select
        {...input}
        onChange={input.onChange}
        onBlur={() => input.onBlur(undefined)}
        options={options}
        name={name}
        isSearchable={isSearchable}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isMulti={isMulti}
        className={`react-select ${className} ${
          touched && error ? 'custom-error-select-border' : ''
        }`}
      />
      {touched && error && <div className="error-text">{error}</div>}
    </>
  );
};
