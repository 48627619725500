import React, { PropsWithChildren } from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';
import { Loading } from './components/common';
import { history, persistor, store } from './store';

import '../node_modules/font-awesome/css/font-awesome.css';
import 'react-toastify/dist/ReactToastify.css';

const AppProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <ConnectedRouter history={history} context={ReactReduxContext}>
        {children}
      </ConnectedRouter>
      <ToastContainer
        position="bottom-center"
        autoClose={10000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
    </PersistGate>
  </Provider>
);

export default AppProvider;
