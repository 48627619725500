import { createRoutine } from 'redux-saga-routines';
import { IAuthRedux } from './auth.interface';

export const ON_GET_ORGANIZATION_INFO = createRoutine(
  'ON_GET_ORGANIZATION_INFO'
);

export const INITIAL_STATE: IAuthRedux = {
  // @ts-ignore
  orgInfo: {},
  loading: true,
};

export default (
  state = INITIAL_STATE,
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case ON_GET_ORGANIZATION_INFO.TRIGGER:
      return { ...state, orgInfo: {}, loading: true };
    case ON_GET_ORGANIZATION_INFO.SUCCESS:
      return { ...state, ...payload };
    case ON_GET_ORGANIZATION_INFO.FAILURE:
      return { ...state };
    case ON_GET_ORGANIZATION_INFO.FULFILL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
