import { all } from 'redux-saga/effects';
import API from '../services/api';

import initRefDataSagas from './refdata.sagas';
import initStateSagas from './states.sagas';
import initStartUpSagas from './startup.sagas';
import initCountiesSagas from './counties.sagas';
import { initChildrenSaga } from '../pages/children';
import { initAuthSagas } from '../pages/auth';
import { initPaymentSagas } from '../pages/payment';

import { Env } from '../utils';

const { create } = API;

const BASEURL = Env.REACT_APP_API_BASE;

export const api = create(BASEURL);

export default function* root() {
  yield all([
    // RefData sagas
    ...initRefDataSagas(api),

    // startup sagas
    ...initStartUpSagas(api),

    // Children Data
    ...initChildrenSaga(api),

    // Auth
    ...initAuthSagas(api),

    // Payment
    ...initPaymentSagas(api),
    //States
    ...initStateSagas(api),
    //Counties
    ...initCountiesSagas(api),
  ]);
}
